import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
} from '@mui/material';

import { ZustandContext } from '@store/modalStore';
import { ModalCofirmVehicle } from '@modals/ModalConfirmVehicle';
import { ModalContratorData } from '@modals/ModalContratorData';
import { usePolicyStore } from '@store/policyStore';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { calculateAge } from '@utils/utilityFunctions';
import { getVehicleType } from '@utils/vehicleType';

export const LeftCard: React.FC = () => {
  const { breakpoints } = useTheme();
  const { show, hide } = React.useContext(ZustandContext);
  const { policy } = usePolicyStore();
  const { car } = useCarStore();
  const { driver } = useDriverStore();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const vehicletype = car.type ? car.type : '';

  return (
    <Box
      padding={`${isMobile ? '0px' : '30px'}`}
      marginTop={isTablet ? 2 : 0}
      width={isMobile ? '100%' : isTablet ? '90%' : '100%'}
      style={{
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
    >
      <Box
        style={{
          borderBottomStyle: 'dashed',
          borderBottom: '1px dashed #5E6A71',
        }}
        padding={`${isMobile ? '14px' : '0px'}`}
      >
        <Box textAlign="center">
          <Box
            component={'img'}
            src={getVehicleType(vehicletype)}
            sx={{
              width: 148,
            }}
            alt={'camioneta'}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 'bold', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 15 : 18}
            margin={`${!isMobile ? '25px 0px 0px 0px' : '0px 0px 0px 0px'}`}
          >
            Vehículo
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
            margin={`${!isMobile ? '25px 0px 0px 0px' : '0px 0px 0px 0px'}`}
          >
            Marca/Modelo:
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'bold', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            {`${car.assembler}`}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" margin="0px 0px 15px">
          <Typography
            variant="caption"
            style={{ fontWeight: 'lighter', color: '#424242' }}
            textAlign="end"
            fontSize={isTablet ? 13 : 15}
          >
            {`${car.description}`}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Año:
          </Typography>
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold', color: '#424242' }}
            fontSize={isTablet ? 13 : 15}
          >
            {car.model}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          margin="15px 0px"
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Número de serie (NIV):
          </Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Typography
              variant="caption"
              style={{ fontWeight: 'bold', color: '#424242' }}
              fontSize={isTablet ? 13 : 15}
            >
              {policy.Niv}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 'bold', color: '#424242' }}
              textAlign="start"
              fontSize={isTablet ? 13 : 15}
            >
              <IconButton
                onClick={() => show(ModalCofirmVehicle, { onClose: hide })}
              >
                <EditIcon style={{ color: '#004876' }} />
              </IconButton>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          margin="15px 0px 25px"
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Número de placas (opcional)
          </Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Typography
              variant="caption"
              style={{ fontWeight: 'bold', color: '#424242' }}
              fontSize={isTablet ? 13 : 15}
            >
              {policy.plate}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 'bold' }}
              textAlign="start"
              fontSize={isTablet ? 13 : 15}
            >
            </Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            width={30}
            height={30}
            borderRadius={100}
            left={isMobile ? -20 : isTablet ? 0 : 0}
            top={isMobile ? '23.5%' : isTablet ? '26.3%' : '53.5%'}
            position={'absolute'}
            style={{ backgroundColor: '#f5f5f5' }}
          />
          <Box
            width={30}
            height={30}
            borderRadius={100}
            left={isMobile ? '97%' : isTablet ? 680 : 580}
            top={isMobile ? '23.5%' : isTablet ? '26.3%' : '53.5%'}
            position={'absolute'}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </Box>
      </Box>
      <Box
        marginTop={2}
        padding={`${isMobile ? '14px' : '0px'}`}
        style={
          isTablet
            ? {
                borderBottomStyle: 'dashed',
                borderBottom: '1px dashed #5E6A71',
              }
            : {}
        }
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 'bold', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 15 : 15}
          >
            Contratante
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'bold', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            <IconButton
              onClick={() => show(ModalContratorData, { onClose: hide })}
            >
              <EditIcon style={{ color: '#004876' }} />
            </IconButton>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Nombre:
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'bold', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            {policy.contrator?.firstName
              ? `${policy.contrator.firstName} ${policy.contrator.middleName}`
              : `${policy.driver.firstName} ${policy.driver.middleName}`}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Sexo de nacimiento:
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'bold', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            {policy.contrator?.genero ? policy.contrator?.genero : driver.genero}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Edad:
          </Typography>
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold', color: '#424242' }}
            fontSize={isTablet ? 13 : 15}
          >
            {calculateAge(
              policy.contrator?.fechaNacimiento
                ? policy.contrator?.fechaNacimiento
                : driver.fechaNacimiento
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            RFC:
          </Typography>
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold', color: '#424242' }}
            fontSize={isTablet ? 13 : 15}
          >
            {policy.contrator?.RFC ? policy.contrator?.RFC : policy.driver.RFC}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Correo electrónico:
          </Typography>
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold', color: '#424242' }}
            fontSize={isTablet ? 13 : 15}
          >
            {policy.contrator?.email ? policy.contrator?.email : driver.email}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Teléfono:
          </Typography>
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold', color: '#424242' }}
            fontSize={isTablet ? 13 : 15}
          >
            {policy.contrator?.telefono
              ? policy.contrator?.telefono
              : driver.telefono}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
          marginBottom={3}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={isTablet ? 13 : 15}
          >
            Domicilio Fiscal:
          </Typography>
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold', width: '50%', color: '#424242' }}
            fontSize={isTablet ? 13 : 15}
          >
            {policy.taxResidence.street ? `${policy.taxResidence.street} ${policy.taxResidence.numeroExterior}, ${policy.taxResidence.colonia_name}, C.P. ${policy.taxResidence.zip}, ${policy.taxResidence.estado_name}, México` : `${policy.street} ${policy.numeroExterior}, ${policy.colonia_name}, C.P. ${driver.codigoPostal}, ${policy.estado_name}, México`}
            {}
          </Typography>
        </Box>
        {isTablet ? (
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box
              width={30}
              height={30}
              borderRadius={100}
              left={isMobile ? -20 : isTablet ? 0 : 0}
              top={isMobile ? '47%' : isTablet ? '44.5%' : '54.8%'}
              position={'absolute'}
              sx={{ backgroundColor: {sm:'#f5f5f5'} }}
            />
            <Box
              width={30}
              height={30}
              borderRadius={100}
              left={isMobile ? '97%' : isTablet ? 680 : 580}
              top={isMobile ? '47%' : isTablet ? '44.5%' : '54.8%'}
              position={'absolute'}
              sx={{ backgroundColor:{sm:'#f5f5f5'} }}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
