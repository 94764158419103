import React, {useEffect, useState} from "react";
import {Box, Button, Container, Grid, Theme, useMediaQuery, useTheme} from "@mui/material";
import {useCarStore} from "@store/carInfoStore";
import {useDriverStore} from "@store/driverInfoStore";
import {useSelectedQuoteStore} from "@store/QuoteStore";
import {usePolicyStore} from "@store/policyStore";
import {useTrackingStore} from "@store/TrackingStore";
import {useTokenStore} from "@store/TokenStore";
import {useCPStore} from "@store/cpStore";
import {useHistory} from "react-router-dom";
import {useCarListStore} from "@store/carListStore";
import {payment} from "@api/payment";
import {getTracking} from "@api/getTracking";
import {getPoliza} from "@api/getPoliza";
import {upCRM} from "@utils/CRM";
import {getVIN} from "@api/getVIN";
import {getPayment} from "@api/getPayment";
import {useChangePrice} from "@hooks/useChangePrice";
import {makeStyles} from "@mui/styles";
import Loading from "@components/Loading/Loading";
import {CustomButton} from "@buttons/CustomButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {Header} from "@navigationMenu/Header";
import Vigency from "@options/Vigency";
import {Code} from "@screens/payment/Code";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding:0,
        background: '#f5f5f5',
        paddingBottom: '60px',
    },
}));


type PaymentDataProps = {
    expanded: boolean;
    activeStep:number;
    setActiveStep: (id: number) => void;
};
export const PaymentData: React.FC<PaymentDataProps> =  ({ expanded, setActiveStep, activeStep}) => {
    const classes = useStyles();

    const { breakpoints, palette } = useTheme();

    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const [url, setUrl] = useState<any>('');
    const { car: Car } = useCarStore();
    const { driver } = useDriverStore();
    const { selectedQuote } = useSelectedQuoteStore();
    const { policy, addPolicyInfo } = usePolicyStore();
    const { tracking } = useTrackingStore();
    const { token } = useTokenStore();
    const { cpData } = useCPStore();
    const history = useHistory();
    const { carList } = useCarListStore();
    const [loading, setLoading] = React.useState(false);

    let position = 0

    if (selectedQuote.package === "AMP") {
        position = 0
    }
    if (selectedQuote.package === "LIMIT") {
        position = 1
    }
    if (selectedQuote.package === "RC") {
        position = 2
    }

    function handleIframe() {
        setLoading(true);
        (async () => {
            let qdata = {
                business: 'coppel-hdi',
                insurance: selectedQuote.insurance,
                quote_id: selectedQuote.quote_id,
            };
            const { data } = await payment(token, qdata);
            let initialData = {
                id: tracking.id,
                business: 'coppel-hdi',
                data: {
                    car: Car,
                    driver: driver,
                    selectedQuote: selectedQuote,
                    policy: policy,
                    cpData: cpData,
                    carList: carList,
                },
            };
            getTracking(token, initialData);
            if (data.policy_paid) {
                var dataInfo = {
                    Poliza: data.policy_id,
                    business: "coppel-hdi",
                    insurance: selectedQuote.insurance,
                    tracking_id: tracking.id
                }
                let dataPoliza = await getPoliza(token, dataInfo);
                setLoading(false);
                policy.stage = '4. Emisión (Coche)';
                policy.sub_stage = '7. Pago';
                policy.state_code = 'Lograda';
                policy.status_code = 'Lograda';
                policy.url_poliza = dataPoliza?.data?.policy_url;
                policy.no_poliza = data.policy_id;
                addPolicyInfo({ ...policy });
                upCRM(
                    token,
                    Car,
                    driver,
                    selectedQuote,
                    policy,
                    tracking,
                    cpData,
                    selectedQuote,
                    url
                );
                setActiveStep(0)
                history.push('/welcome');
            } else {
                setLoading(false);
                policy.stage = '3. Contratación (Coche)';
                policy.sub_stage = '6. Datos Bancarios';
                policy.state_code = 'Activa';
                policy.status_code = 'Lograda';
                addPolicyInfo({ ...policy });
                upCRM(
                    token,
                    Car,
                    driver,
                    selectedQuote,
                    policy,
                    tracking,
                    cpData,
                    selectedQuote,
                    url
                );
            }
        })();
    }

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const { data } = await getVIN(policy.Niv, token);
            if (!data?.is_active_policy) {
                let date = new Date();
                let day = `${date.getDate()}`.padStart(2, '0');
                let month = `${date.getMonth() + 1}`.padStart(2, '0');
                let year = date.getFullYear();
                let fechaHoy = `${year}-${month}-${day}`;
                const Quote = {
                    tracking_id: tracking.id,
                    quote_id: selectedQuote.quote_id,
                    business: 'coppel-hdi',
                    insurance: selectedQuote.insurance,
                    package_id: selectedQuote.available_packages[position].Clave,
                    package: selectedQuote.package,
                    payment_plan: '',
                    payment_option: selectedQuote.payment_option,
                    payment_card: '',
                    driver_first_name: policy.driver.firstName,
                    driver_last_name: policy.driver.middleName,
                    driver_second_last_name: policy.driver.lastName,
                    driver_phone: driver.telefono,
                    driver_mail: driver.email,
                    driver_gender: driver.genero === 'Hombre' ? 'M' : 'F',
                    driver_birth_date: driver.fechaNacimiento,
                    driver_location: '',
                    driver_rfc: policy.driver.RFC,
                    driver_zip_code: driver.codigoPostal,
                    driver_state_code: policy.estado,
                    driver_township_code: cpData[0].township_code,
                    driver_suburb_code: policy.colonia,
                    driver_street: policy.street,
                    driver_ext: policy.numeroExterior,
                    driver_int: policy.numeroInterior,
                    vehicle_id: Car.vehicle_id,
                    vehicle_service: 'PARTICULAR',
                    vehicle_use: 'NORMAL',
                    vehicle_vin: policy.Niv,
                    vehicle_plate: policy.plate,
                    vehicle_type: Car.type.toUpperCase(),
                    vehicle_model: Car.model,
                    legal_agreement: [
                        {
                            agreement:
                                'Estoy de acuerdo con la descripción del vehículo seleccionada y misma que  se  muestra  en  la  parte superior de esta pantalla.',
                            acceptance: true,
                        },
                        {
                            agreement:
                                'Mi vehículo no es utilizado ni será utilizado para presentar servicios de entrega por paquetería, a domicilio y/o similares.',
                            acceptance: true,
                        },
                        {
                            agreement:
                                'No he recibido indemnización alguna de parte de alguna aseguradora derivado de la pérdida total de mi vehículo.',
                            acceptance: true,
                        },
                        {
                            agreement:
                                'Mi vehículo no se encuentra chocado o siniestrado de alguna forma.',
                            acceptance: true,
                        },
                        {
                            agreement: 'He leído y acepto el Aviso de privacidad.',
                            acceptance: true,
                        },
                        {
                            agreement:
                                'Estoy de acuerdo con las Condiciones Generales y Términos de Uso.',
                            acceptance: true,
                        },
                    ],
                    contractor: policy.selectorDriver === 'No' ? 'false' : 'true',
                    contractor_first_name: policy.contrator?.firstName,
                    contractor_lastname: policy.contrator?.middleName,
                    contractor_second_lastname: policy.contrator?.lastName,
                    contractor_birth_date: policy.contrator?.fechaNacimiento,
                    contractor_rfc: policy.contrator?.RFC,
                    contractor_email: policy.contrator?.email,
                    contractor_phone: policy.contrator?.telefono,
                    contractor_gender: policy.contrator?.genero === 'Hombre' ? 'M' : 'F',
                    contractor_zip_code: policy.taxResidence.zip,
                    contractor_state_code: policy.taxResidence.estado,
                    contractor_township_code: policy.taxResidence.city,
                    contractor_suburb_code: policy.taxResidence.colonia,
                    contractor_street: policy.taxResidence.street,
                    contractor_ext: policy.taxResidence.numeroExterior,
                    contractor_int: policy.taxResidence.numeroInterior,
                    legal_zip_code: policy.taxResidence.zip,
                    legal_state_code: policy.taxResidence.estado,
                    legal_township_code: policy.taxResidence.city,
                    legal_suburb_code: policy.taxResidence.colonia,
                    legal_street: policy.taxResidence.street,
                    legal_ext: policy.taxResidence.numeroExterior,
                    legal_int: policy.taxResidence.numeroInterior,
                    invoice: policy.bill,
                    domicialiacion: policy.domicialiacion,
                    cfdi_tax_regime: policy.regimen || '616',
                    use_tax_regime: 'G03',
                    promo_code: policy.code,
                    associate_id: policy.employee_id,
                    emision_date: fechaHoy,
                    start_at: fechaHoy,
                };
                try {
                    const { data } = await getPayment(token, Quote);
                    if (data) {
                        await setUrl(data?.payment_data?.service_url);
                        setLoading(false);
                        if (selectedQuote.insurance === 'aba') {
                            window.location.href = data?.payment_data?.service_url;
                        }
                    } else if (data === undefined) {
                        if (
                            policy.Niv === '' ||
                            policy.contrator?.firstName === '' ||
                            policy.driver.firstName === '' ||
                            policy.street === '' ||
                            Car.assembler === '' ||
                            driver.codigoPostal === ''
                        ) {
                            history.push('/hiring');
                        } else {
                            history.push('/welcome3');
                        }
                        setLoading(false);
                    }
                } catch (error) {
                    console.log(error);
                    if (
                        policy.Niv === '' ||
                        policy.contrator?.firstName === '' ||
                        policy.driver.firstName === '' ||
                        policy.street === '' ||
                        Car.assembler === '' ||
                        driver.codigoPostal === ''
                    ) {
                        history.push('/hiring');
                    } else {
                        history.push('/welcome3');
                    }
                    setLoading(false);
                }
            } else {
                if (
                    policy.Niv === '' ||
                    policy.contrator?.firstName === '' ||
                    policy.driver.firstName === '' ||
                    policy.street === '' ||
                    Car.assembler === '' ||
                    driver.codigoPostal === ''
                ) {
                    setLoading(false);
                    history.push('/hiring');
                } else {
                    if (selectedQuote.insurance === data.insurance) {
                        var dataInfo = {
                            Poliza: data.policy_id,
                            business: "coppel-hdi",
                            insurance: selectedQuote.insurance,
                            tracking_id: tracking.id
                        }
                        let dataPoliza = await getPoliza(token, dataInfo);
                        policy.url_poliza = dataPoliza?.data?.policy_url;
                        addPolicyInfo({ ...policy });
                        setLoading(false);
                        history.push('/welcome');
                    } else {
                        setLoading(false);
                        history.push('/welcome3');
                    }

                }
            }
        })();
    }, []);
    useChangePrice();

    return(<>
        <div className={classes.root} style={{display: expanded ? 'block' : 'none'}}>
            <Loading open={loading} handleClose={() => true}/>


                <Box
                    sx={{flexGrow: 0}}
                    padding={isMobile ? -0 : 2}
                    style={{
                        position: 'relative',
                        minHeight: '70vh',
                        marginTop: 10,
                    }}
                >
                    <Grid container marginTop={2}>
                        <Grid xs={12} md={12} lg={6} style={{position: 'relative'}}>
                            <Box
                                sx={{
                                    height: {xs:'80vh',sm:'80vh',md:'90vh'},
                                    backgroundColor: {xs:'white',md:'white'},
                                    borderRadius: {xs:2,md:2}
                                }}
                            >
                                <iframe
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: '0px',
                                        position: 'relative',
                                    }}
                                    onLoad={handleIframe}
                                    src={url}
                                    title="payment"
                                    id="payment"
                                />
                            </Box>
                        </Grid>
                        <Grid xs={12} md={12} lg={6}>
                            <Box marginLeft={isTablet ? 0 : 8}>
                                <Vigency isPayment={true}/>
                                {policy.Niv !== '' &&
                                    policy.contrator?.firstName !== '' &&
                                    policy.driver.firstName !== '' &&
                                    policy.street !== '' &&
                                    Car.assembler !== '' &&
                                    driver.codigoPostal !== '' && <Code/>}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
        </div>
    </>)
}
