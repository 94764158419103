import React from 'react';
import PolicyItem from '@screens/customerPortal/policyListSlider/PolicyItem';
import { useTheme, useMediaQuery, Typography } from '@mui/material';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Navigation, Pagination } from 'swiper/modules';
import { Box } from '@mui/material';
import './Swiper.css';
import CircularProgress from '@mui/material/CircularProgress';

export interface PolicyListSlideItem {
  numero_poliza: string;
  nombre_contratante: string;
  fecha_inicio: string;
  fecha_fin: string;

}

type PolicyListSlideProps = {
  items: PolicyListSlideItem[];
  loading:boolean;
};

const PolicyListSlider: React.FC<PolicyListSlideProps> = ({ items, loading }) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  /*  const isMobile = useMediaQuery(breakpoints.down('sm'));*/
  const isTablet = useMediaQuery(breakpoints.down('md'));

  return (
    <>
      {
        !loading  && items.length > 0 ?
          <Swiper
            autoHeight={true}
            spaceBetween={1}
            navigation={isTablet ? false : true}
            pagination={{
              clickable: true,
              enabled: isDesktop ? false : true
            }}
            modules={[Navigation, Pagination]}
            className="Swiper"
          >

            {items.map((component_: any, index: any) => {
                return (
                  <SwiperSlide className="Swiper-slide" key={index}>
                    <PolicyItem item={component_} />
                  </SwiperSlide>
                );
              }
            )}

          </Swiper>

          :
          loading ?
            <Box sx={{
              display: 'flex',
              width: { xs: '100%', md: '461px' },
              height: '396.172px',
              padding: { xs: '16px 0px 10px 0px', md: '16px 8px' },
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: '12px',
              flexShrink: 0,
              borderRadius: '16px',
              background: '#FFF'
            }}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{color:'#004876'}}/>
              </Box>
            </Box>
            :
          <Box sx={{
            display: 'flex',
            width: { xs: '100%', md: '461px' },
            height: '396.172px',
            padding: { xs: '16px 0px 10px 0px', md: '16px 8px' },
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: '12px',
            flexShrink: 0,
            borderRadius: '16px',
            background: '#FFF'
          }}>
            <Typography
              sx={{
                color: 'var(--black-75, #595959)',
                fontFamily: 'Montserrat',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                maxWidth: '267px'
              }}
            >
              Aun no cuentas con polizas disponibles
            </Typography>
          </Box>
      }
    </>
  );
};
export default PolicyListSlider;
