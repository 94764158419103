import React, {useEffect} from "react";
import {makeStyles} from "@mui/styles";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Grid,
    Theme,
    useMediaQuery,
    useScrollTrigger,
    useTheme
} from "@mui/material";
import {ZustandContext} from "@store/modalStore";
import {useHistory} from "react-router-dom";
import {usePolicyStore} from "@store/policyStore";
import {useCarStore} from "@store/carInfoStore";
import {useDriverStore} from "@store/driverInfoStore";
import {useChangePrice} from "@hooks/useChangePrice";
import {Toats} from "@components/alerts/Toats";
import {ModalPay} from "@modals/ModalPay";
import {CustomButton} from "@buttons/CustomButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {Header} from "@navigationMenu/Header";
import {PolicyNumber} from "@screens/hiring/confirm/policyNumber/PolicyNumber";
import {LeftCard} from "@screens/hiring/confirm/LeftCard";
import {RightCard} from "@screens/hiring/confirm/RightCard";
import {DigicertField} from "@navigationMenu/DigicertField";
import {ModalChangeCP} from "@modals/ModalChangeCP";
import Vigency from "@options/Vigency";
import {Code} from "@screens/payment/Code";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: '#f5f5f5',
        paddingBottom: '100px',
    },
}));


type ConfirmDataProps = {
    expanded: boolean;
    activeStep:number;
    setActiveStep: (id: number) => void;
};
export const ConfirmData: React.FC<ConfirmDataProps> =  ({ expanded, setActiveStep,activeStep}) => {

    const classes = useStyles();
    const { breakpoints, palette } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const { show, hide,state } = React.useContext(ZustandContext);
    const history = useHistory();

    const { policy } = usePolicyStore();
    const { car } = useCarStore();

    const trigger = useScrollTrigger({
        threshold: 970,
        disableHysteresis: true,
    });

    const { driver } = useDriverStore();
    const [showToast, setShowToast] = React.useState(false);


    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    useEffect(() => {
        if (activeStep ===3){
            if (
                policy.Niv === '' ||
                policy.contrator?.firstName === '' ||
                policy.driver.firstName === '' ||
                policy.street === '' ||
                car.assembler === '' ||
                driver.codigoPostal === ''
            ) {
              setActiveStep(2)
            }
        }

    }, [activeStep]);

    useChangePrice();




    return(
        <>
                <div className={classes.root} style={{display: expanded ? 'block' : 'none'}}>
                    {/* {!loading && ( */}
                    <Toats
                        showToast={showToast}
                        setShowToast={setShowToast}
                        Text="Las modalidades de pago mensual, trimestral y semestral, solo aplican para pagos domiciliados"
                        isWarning
                    />
                    {/* )} */}
                    {isMobile && !trigger ? (
                        <Button
                            onClick={() => {
                                setActiveStep(4)
                            }}

                            fullWidth
                            variant={'contained'}
                            style={{
                                position: 'fixed',
                                zIndex: 3,
                                textTransform: 'none',
                                height: 45,
                                bottom: 0,
                                borderRadius: 0,
                            }}
                        >
                            Pagar
                        </Button>
                    ) : null}

                        <Box
                            sx={{flexGrow: 1}}
                            padding={isMobile ? 0 : 2}
                            style={{
                                position: 'relative',
                                minHeight: '70vh',
                                marginTop: 10,
                            }}
                        >
                            <Grid
                                container
                                spacing={isTablet ? 0 : 10}
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'start'}
                                justifyContent={'space-between'}
                            >
                                <Grid item xs={12} md={12} lg={6} sx={{marginTop: '21px'}}>
                                    {policy.Niv !== '' &&
                                        policy?.contrator?.firstName !== '' &&
                                        policy?.driver?.firstName !== '' &&
                                        policy?.street !== '' &&
                                        car?.assembler !== '' &&
                                        driver?.codigoPostal !== '' && <LeftCard/>}
                                </Grid>
                                <Grid item xs={12} md={12} lg={6} sx={{marginTop: '21px'}}>
                                    <Vigency  isPayment={true} />
                                    {policy.Niv !== '' &&
                                        policy?.contrator?.firstName !== '' &&
                                        policy?.driver?.firstName !== '' &&
                                        policy?.street !== '' &&
                                        car?.assembler !== '' &&
                                        driver?.codigoPostal !== '' && <RightCard setShowToast={setShowToast}  setActiveStep={setActiveStep}/>}
                                </Grid>

                            </Grid>
                        </Box>
                </div>
                {isTablet ? <DigicertField/> : null}
          </>
    )

}
