import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import validator from 'validator';
import Accordion from '@mui/material/Accordion';
import { styled } from '@mui/styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ArrowForward, Check, Error } from '@mui/icons-material';
import {
  Box,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Checkbox,
  Grid,
  AccordionSummary,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import { useForm } from '@hooks/useForm';
import { useCarStore } from '@store/carInfoStore';
import { usePolicyStore } from '@store/policyStore';
import { getVehicleType } from '@utils/vehicleType';
import { getTracking } from '@api/getTracking';
import { useCarListStore } from '@store/carListStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useDriverStore } from '@store/driverInfoStore';
import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCPStore } from '@store/cpStore';
import { ZustandContext } from '@store/modalStore';
import { NivCustomMask } from '@inputCustomMasks/NivCustomMasks';
import { PlatesCustomMask } from '@inputCustomMasks/PlatesCustomMasks';
import { getVIN } from '../../../api/getVIN';
import { ModalNivRegister } from '../../modals/ModalHome/ModalNivRegister';

type CarDataProps = {
  expanded: boolean;
  setExpanded: () => void;
  setActiveStep: (id: number) => void;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorToast: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CarData: React.FC<CarDataProps> = ({
  expanded,
  setExpanded, setActiveStep,
  setShowToast,
  setShowErrorToast,
}) => {
  const { breakpoints, palette } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const { car } = useCarStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { driver } = useDriverStore();
  const { token } = useTokenStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { carList } = useCarListStore();
  const { show, hide } = useContext(ZustandContext);

  const [checked, setChecked] = useState<boolean>(policy.checks.first || false);
  const [totalLost, setTotalLost] = useState<boolean>(
    policy.checks.second || false
  );
  const [services, setServices] = useState<boolean>(
    policy.checks.third || false
  );
  const [legal, setLegal] = useState<boolean>(policy.checks.fourth || false);
  const [errorVIN, setErrorVIN] = useState({ msg: '', isError: false });
  const [warningVIN, setWarningVIN] = useState({ msg: '', isWarning: false });

  const [formState, setFormState] = useForm({
    brand: `${car.assembler}`,
    year: car.model,
    description: car.description ? car.description : '',
    niv: policy.Niv || '',
    plates: policy.plate || '',
  });

  const [validateFields, setValidateFields] = useState({
    niv: false
  });

  const { brand, year, description, niv, plates } = formState;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleChangeLost = (event: ChangeEvent<HTMLInputElement>) => {
    setTotalLost(event.target.checked);
  };
  const handleChangeServices = (event: ChangeEvent<HTMLInputElement>) => {
    setServices(event.target.checked);
  };

  const handleChangeLegal = (event: ChangeEvent<HTMLInputElement>) => {
    setLegal(event.target.checked);
  };

  const isValidForm = (): boolean => {
    let date = new Date();
    let yearN = date.getFullYear() + 1;
    if (niv.length < 10) {
      return false;
    } else if (brand === '') {
      return false;
    } else if (year === '') {
      return false;
    } else if (year.length !== 4) {
      return false;
    } else if (!validator.isNumeric(year)) {
      return false;
    } else if (!(parseInt(year) <= yearN)) {
      return false;
    } else if (!checked) {
      return false;
    } else if (!totalLost) {
      return false;
    } else if (!services) {
      return false;
    } else if (!legal) {
      return false;
    } else if (plates.length === 1 && plates.length < 6) {
      return false;
    }
    return true;
  };

  const [found, setFound] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [insuranceVal, setInsuranceVal] = useState('');

  const continuar = () => {
    if (!isValidForm()) {
      setShowErrorToast(true);
      return;
    }
    setShowErrorToast(false);
    setShowToast(true);
    const newPolicy = {
      Niv: niv,
      plate: plates ? plates : vehicletype === 'MOTO' ? '00000' : '000000',
      checks: {
        first: checked,
        second: totalLost,
        third: services,
        fourth: legal,
      },
    };
    policy.stage = '3. Contratación (Coche)';
    policy.sub_stage = '4. Datos Complementarios Asegurado';
    policy.state_code = 'Activa';
    policy.status_code = 'En curso';
    addPolicyInfo({ ...policy, ...newPolicy });
    setActiveStep(1);
    let initialData = {
      id: tracking.id,
      business: 'coppel-hdi',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
        carList: carList,
      },
    };
    getTracking(token, initialData);
    upCRM(
      token,
      car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );
    setExpanded();
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const vehicletype = car.type ? car.type : '';

  //Recopilamos datos de la poliza

  useEffect(() => {
    const getVin = async () => {
      const data = await getVIN(niv, token);
      if (data && data.data && data.data.is_active_policy) {
        setFound(true);
        setInsuranceVal(data.data.insurance);
        const foundPolicy = data.data.policy_id;
        show(ModalNivRegister, {
          onClose: hide,
          foundPolicy,
        });

        return;
      }
      setFound(false);
      setInsuranceVal('');
    };
    getVin();
  }, [niv]);

  //Escuchamos si existe poliza aplicamos la lógica

  useEffect(() => {
    if ((found && niv.length >= 10) || insuranceVal === 'zurich') {
      setError(true);
      setMessage(
        'Oops lo Sentimos se encontró póliza registrada, favor de verificarlo'
      );
      return;
    }
    setError(false);
    setMessage('');
  }, [found, insuranceVal]);

  const validateVIN = (value: string) => {
    if (value.length === 0) {
      setErrorVIN({
        msg: 'Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningVIN({ msg: '', isWarning: false });
      return
    }

    if ( value.length < 10) {
      setErrorVIN({
        msg: 'El número de serie debe tener entre 10 y 17 caracteres.',
        isError: true,
      });
      return
    }

    setWarningVIN({ msg: '', isWarning: false });
    setErrorVIN({ msg: '', isError: false });
  };

  return (
    <Accordion
      expanded={expanded}
      style={{
        border: 'none',
        width:'100%',
        display: expanded ? 'block':'none',
        textDecoration: "none",
        marginBottom: '25px',
        borderRadius: '8px',
        marginTop:'40px'
      }}
      elevation={0}
    >
      <AccordionSummary
        style={{
          height: 0,
          padding: 0,
          minHeight: 0,
          border: 'none',
        }}
      ></AccordionSummary>
      <AccordionDetails style={{ padding: 0}}>
        <Box
          paddingY={{ xs: 2 }}
          paddingX={{ xs: 2, md: 4 }}
          paddingTop={{ xs: 3, lg: 8, md: 8 }}
        >
          <Grid container spacing={{ xs: 2, md: 3 }} paddingBottom={6}>
            {!isTablet && (
              <Grid item container lg={4} md={6} justifyContent={'center'}>
                <Grid item>
                  <Box
                    component={'img'}
                    src={getVehicleType(vehicletype)}
                    sx={{
                      width: 200,
                    }}
                    alt="Jeep"
                    style={{ objectFit: 'contain', marginTop: 0 }}
                    margin="30px"
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} lg={5} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }} marginBottom={'24px'}>
                <Grid item>
                  <Typography fontSize={14} fontWeight={400} color={'#212121'}>
                    Completa los datos del vehículo a asegurar
                  </Typography>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    fullWidth
                    name={'brand'}
                    disabled
                    value={brand}
                    label="Marca"
                    variant="outlined"
                    maxRows={4}
                    onChange={(e) => setFormState(e)}
                    color={'success'}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        brand !== '' ? <Check color={'success'} /> : null,
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={3}>
                  <TextField
                    fullWidth
                    label="Año*"
                    name={'year'}
                    disabled
                    value={year}
                    variant="outlined"
                    maxRows={4}
                    onChange={(e) => setFormState(e)}
                    color={
                      year !== '' &&
                      year.length === 4 &&
                      validator.isNumeric(year) &&
                      parseInt(year) <= 2022
                        ? 'success'
                        : 'success'
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        year !== '' &&
                        year.length === 4 &&
                        validator.isNumeric(year) &&
                        parseInt(year) <= 2022 ? (
                          <Check color={'success'} />
                        ) : null,
                    }}
                  />
                </Grid>
              </Grid>
              <TextField
                fullWidth
                name={'description'}
                value={description}
                disabled
                onChange={(e) => setFormState(e)}
                label="Descripción"
                variant="outlined"
                maxRows={4}
                style={{ marginBottom: '24px' }}
                color={'success'}
                InputProps={{
                  style: {
                    borderRadius: 8,
                  },
                  endAdornment:
                    description.length !== 0 ? (
                      <Check color={'success'} />
                    ) : null,
                }}
              />
              <HTMLTooltip
                arrow
                placement={'right'}
                disableFocusListener={isTablet}
                disableHoverListener={isTablet}
                disableTouchListener={isTablet}
                title={
                  <>
                    <Typography
                      fontSize={16}
                      marginBottom={1}
                      fontWeight={'600'}
                    >
                    {/*  {'Número de serie (VIN)'}*/}
                    </Typography>
                    <Typography fontSize={12} fontWeight={'400'}>
                      Recuerda que el número de serie debe coincidir con el vehículo que ingresaste previamente en tu cotización
                    </Typography>
                  </>
                }
              >
                <TextField
                  fullWidth
                  value={niv}
                  name={'niv'}
                  onChange={(e: any) => {
                    if (e.target.value.length <= 17) {
                      setFormState(e);
                      validateVIN(e.target.value);
                      setValidateFields({
                        ...validateFields,
                        niv: true
                      });
                    }
                  }}
                  onBlur={() => {
                    validateVIN(niv);
                    setValidateFields({
                      ...validateFields,
                      niv: true
                    });
                  }}
                  error={error || errorVIN.isError}
                  helperText={
                    <label
                      style={{
                        color:
                          error || errorVIN.isError ? '#f44336' : palette.success.main,
                      }}
                    >
                      {message || errorVIN.msg || warningVIN.msg}
                    </label>
                  }
                  label="Número de serie (VIN)*"
                  variant="outlined"
                  maxRows={4}
                  style={{ marginBottom: '24px' }}
                  color={
                    (niv.length >= 10 && niv.length <= 17)
                      ? 'success'
                      : !warningVIN.isWarning
                      ? 'success'
                      : niv.length < 10
                      ? 'warning'
                      : 'success'
                  }
                  InputProps={{
                    inputComponent: NivCustomMask as any,
                    style: {
                      textTransform: 'capitalize',
                      borderRadius: 8,
                    },
                    endAdornment:
                      ( niv.length >= 10 && niv.length <= 17 ) && !error ? (
                        <Check color={'success'} />
                      ) : ( validateFields.niv && <Error color={'error'} /> )
                  }}
                />
              </HTMLTooltip>
              <HTMLTooltip
                arrow
                placement={'right'}
                disableFocusListener={isTablet}
                disableHoverListener={isTablet}
                disableTouchListener={isTablet}
                title={
                  <>
                    <Typography
                      fontSize={16}
                      marginBottom={1}
                      fontWeight={'600'}
                    >
                      Número de placas
                    </Typography>
                    <Typography fontSize={12} fontWeight={'400'}>
                      Le ayudará al ajustador a identificar tu vehículo más
                      rápido si tienes un incidente.
                    </Typography>
                  </>
                }
              >
                <TextField
                  fullWidth
                  value={
                    plates
                    // ? plates
                    // : vehicletype === 'MOTO'
                    // ? '00000'
                    // : '000000'
                  }
                  name={'plates'}
                  onChange={(e: any) => {
                    if (e.target.value.length <= 7) {
                      setFormState(e);
                    }
                  }}
                  label="Número de placas (opcional)"
                  variant="outlined"
                  maxRows={4}
                  style={{ marginBottom: '24px' }}
                  color={plates.length >= 6 ? 'success' : 'success'}
                  InputProps={{
                    inputComponent: PlatesCustomMask as any,
                    style: {
                      borderRadius: 8,
                    },
                    endAdornment:
                      plates.length >= 6 ? <Check color={'success'} /> : null,
                  }}
                />
              </HTMLTooltip>

              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                marginBottom="24px"
              >
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  style={{ padding: '0px 10px 0px 0px' }}
                />
                <Typography>
                  El vehículo que quiero asegurar nunca ha sido declarado
                  pérdida total.
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                marginBottom="24px"
              >
                <Checkbox
                  checked={totalLost}
                  onChange={handleChangeLost}
                  inputProps={{ 'aria-label': 'controlled' }}
                  style={{ padding: '0px 10px 0px 0px' }}
                />
                {selectedQuote.insurance === "axa" ? (
                  <Typography>
                    El vehículo no es  utilizado ni será utilizado para presentar servicios de transporte privado,
                    entrega por paquetería a domicilio y/o similares (Excepto para App de Uber)
                  </Typography>) : (
                  <Typography>
                    El vehículo que quiero asegurar no se usa para prestar
                    servicios en apps de transporte privado.
                  </Typography>)
                }
              </Box>
              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                marginBottom="24px"
              >
                <Checkbox
                  checked={services}
                  onChange={handleChangeServices}
                  inputProps={{ 'aria-label': 'controlled' }}
                  style={{ padding: '0px 10px 0px 0px' }}
                />
                <Typography>
                  El vehículo que quiero asegurar no es un auto fronterizo o
                  legalizado.
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                marginBottom="24px"
              >
                <Checkbox
                  checked={legal}
                  onChange={handleChangeLegal}
                  inputProps={{ 'aria-label': 'controlled' }}
                  style={{ padding: '0px 10px 0px 0px' }}
                />
                <Typography>
                  El vehículo que quiero asegurar no se usa para nada comercial.
                </Typography>
              </Box>
              <Typography>*Campos obligatorios</Typography>
            </Grid>
          </Grid>
          <Grid container direction={'row-reverse'}>
            <Grid item display={'flex'} width={'700px'} justifyContent="flex-end">
              <CustomButton
                color={'primary'}
                disabled={!isValidForm() || found}
                onClick={continuar}
                variant="contained"
                style={{
                  textTransform: 'none',
                  height: 56,
                  width: isTablet ? '100%' : '50%',
                }}
                text={'Siguiente'}
                endIcon={<ArrowForward />}
              />
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const HTMLTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(3, 158, 204, 0.1)',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 232,
    borderRadius: 8,
    padding: 16,
  },
});
