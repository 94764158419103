import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { PolicyNumber } from '@screens/hiring/confirm/policyNumber/PolicyNumber';

type HeaderProps = {
  title: string;
  subtitle?: string;
  number?: string;
  isChecked?: boolean;
  color?: string;
  active?: {
    first: boolean;
    second: boolean;
    third: boolean;
  };
  activeStep?: number;
};

export const Header: React.FC<HeaderProps> = ({
  title,
  subtitle = '',
  number = '',
  isChecked = false,
  color = '',
  active,
  activeStep = 1,
}) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  let fontWeight = '';
  let colorNumber = 'rgba(0,0,0,0.15)';

  const {
    location: { pathname },
  } = useHistory();

  if (active === undefined) {
    return (
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {number !== '' &&
          (!isChecked ? (
            <Typography
              fontSize={58}
              fontWeight={'bold'}
              marginRight={1}
              marginLeft={2}
              style={{
                color:
                  pathname === '/confirm'
                    ? 'rgb(0, 0, 0)'
                    : 'rgba(0, 0, 0, 0.15)',
              }}
            >
              {number}
            </Typography>
          ) : (
            <Box marginRight={1}>
              <Check style={{ fontSize: 48 }} color={'success'} />
            </Box>
          ))}
        <Box
          style={{
            width: 5,
            height: 64,
            backgroundColor: !isChecked ? '#FFD60E' : '#5AB52F',
            borderRadius: 100,
          }}
        >
          <p style={{ color: 'transparent' }}>.</p>
        </Box>
        <Box marginLeft={2} />
        <Box display={'flex'} flexDirection={'column'} justifyItems={'center'}>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            fontWeight={'bold'}
            style={{ color: color === '' ? '#212121' : color }}
          >
            {title}
          </Typography>
          {subtitle !== '' && (
            <Typography fontSize={'16px'} marginTop={0.4}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  if (active?.first === true && number === '1') {
    fontWeight = 'bold';
    colorNumber = 'rgb(0, 0, 0)';
  } else if (active?.second === true && number === '2') {
    fontWeight = 'bold';
    colorNumber = 'rgb(0, 0, 0)';
  } else if (active?.third === true && number === '3') {
    fontWeight = 'bold';
    colorNumber = 'rgb(0, 0, 0)';
  }

  return (
    <Box
      display={'flex'}
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'stretch' : 'center'}
      justifyContent={'space-between'}
      gap={isMobile ? 3 : 0}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {number !== '' &&
          (!isChecked ? (
            <Typography
              fontSize={58}
              fontWeight={fontWeight}
              marginRight={1}
              marginLeft={2}
              style={{ color: colorNumber }}
            >
              {number}
            </Typography>
          ) : (
            <Box marginRight={1}>
              <Check style={{ fontSize: 48, color: '#5AB52F' }} />
            </Box>
          ))}
        <Box
          style={{
            width: 5,
            height: 64,
            backgroundColor: !isChecked
              ? fontWeight
                ? '#FFD60E'
                : 'rgba(0,0,0,0.15)'
              : '#5AB52F',
            borderRadius: 100,
          }}
        >
          <p style={{ color: 'transparent' }}>.</p>
        </Box>
        <Box marginLeft={2} />
        <Box display={'flex'} flexDirection={'column'} justifyItems={'center'}>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            fontWeight={'bold'}
            style={{ color: color === '' ? colorNumber : color }}
          >
            {title}
          </Typography>
          {subtitle !== '' && (
            <Typography
              fontSize={'16px'}
              marginTop={0.4}
              style={{ color: !fontWeight ? 'rgba(0, 0, 0, 0.20)' : '' }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        {Number(number) === Number(activeStep) ? null : null}
      </Box>
    </Box>
  );
};
