import React, { useEffect } from 'react';

import { Box, useTheme, useMediaQuery, Checkbox, Typography } from '@mui/material';
//import { Coupon } from './Coupon';
import { BrandConfirm } from './brand/BrandConfirm';
import { ButtonConfirm } from './ButtonConfirm';
import { DetailsConfirm } from './info/DetailsConfirm';
import { useGetQuotePayment } from '@store/QuoteData';
import { usePolicyStore } from '@store/policyStore';

import Vigency from '@options/Vigency';

type Props = {
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveStep?: (id:number)=>void;

}


export const RightCard: React.FC<Props> = ({ setShowToast ,setActiveStep}) => {
  const { breakpoints } = useTheme();
  //const { selectedPlan, paymentMode } = usePlansDataStore();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const [isChecked, setIsChecked] = React.useState(true);
  const [isDisabledConfirm, setIsDisabledConfirm] = React.useState(false)
  const { setPaymentOption, getPaymentOption } = useGetQuotePayment();
  const [isContado, setContado] = React.useState(true);
  const { policy, addPolicyInfo } = usePolicyStore();

  function handleChangeCheckbox() {
    setIsChecked(!isChecked)
    setShowToast(isChecked)
    const paymentOptionValue = getPaymentOption().value;
    if (isChecked === true && (paymentOptionValue === 'M' || paymentOptionValue === '6M' || paymentOptionValue === '3M')) {
      setIsDisabledConfirm(true)
      policy.domicialiacion = true;
      addPolicyInfo({ ...policy, domicialiacion: isDisabledConfirm});
    } else {
      policy.domicialiacion = false;
      addPolicyInfo({ ...policy, domicialiacion: isDisabledConfirm});
      setIsDisabledConfirm(false)
    }
  }

  useEffect(() => {
    const paymentOptionValue = getPaymentOption().value;
    if ((paymentOptionValue === 'M' || paymentOptionValue === '6M' || paymentOptionValue === '3M')) {
      setContado(false);
      policy.domicialiacion = true;
      addPolicyInfo({ ...policy, domicialiacion: true});
    } else {
      setContado(true);
      policy.domicialiacion = false;
      addPolicyInfo({ ...policy, domicialiacion: false});
    }
  }, []);
  
  return (
      <Box
        padding={`${isMobile ? '0px' : '18px'}`}
        height={!isTablet ? 'inherit' : 950}
        width={isMobile ? '100%' : isTablet ? '90%' : '100%'}
        borderRadius={!isTablet ? '10px' : 2}
        style={{
          backgroundColor: 'white',
          marginTop:'23px'
        }}
      >
        <DetailsConfirm />
        <Box
          borderRadius={2}
          marginTop={2}
          style={{
            backgroundColor: 'white',
          }}
        >
          <Box
            padding={isTablet ? 2 : 0}
            style={{
              borderRadius: '10px',
            }}
          >
            {
              /*<Coupon /> */
            }
            {
              /*<Collaborator /> */
            }
            <BrandConfirm />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                marginTop: '10px',
              }}
            >
            {!isContado ?(
              <>
              <Checkbox
                // disabled={getPaymentOption().value === 'M' || getPaymentOption().value === 'S' || getPaymentOption().value === 'T'}
                checked={isChecked}
                style={{ padding: 0 }}
                onChange={() => handleChangeCheckbox()}
              />
              <Typography sx={{ fontSize: 12 }}>
                Acepto la domiciliación del pago de mi seguro
              </Typography>
              </>)
              : ''}
            </Box>
            <ButtonConfirm isDisabled={isDisabledConfirm} setActiveStep={setActiveStep} />
          </Box>
        </Box>
      </Box>
  );
};
