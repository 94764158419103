import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';

import { CarData } from '@screens/hiring/CarData';
import { ContratorData } from '@screens/hiring/ContratorData';
import { Header } from '@navigationMenu/Header';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { DataContrator } from './DataContractor';
import SteppersCustom from "@components/SteppersCustom/SteppersCustom";
import {ConfirmData} from "@screens/hiring/ConfirmData";
import {CalendarViewWeek} from "@mui/icons-material";
import {PaymentData} from "@screens/hiring/PaymentData";

interface Props {
  activeStep:number;
  setActiveStep: (id: number) => void;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorToast: React.Dispatch<React.SetStateAction<boolean>>;
}

type AdditionalConfig = {
  shouldBill: boolean;
  isDriverSameContractor: boolean;
};

export const AccordionHiring = ({ setShowToast, setShowErrorToast,activeStep,setActiveStep }: Props) => {
  const [active, setActive] = useState<{
    first: boolean;
    second: boolean;
    third: boolean;
  }>({
    first: true,
    second: false,
    third: false,
  });
  //const [activeStep, setActiveStep] = useState<number>(0);
  const [checked, setChecked] = useState({
    first: false,
    second: false,
  });
  const [expanded, setExpanded] = useState({
    car: true,
    contrator: false,
    dataContrator: false,
    confirm:false,
    payment:false
  });

  const { first, second } = checked;
  const { car, contrator, dataContrator,confirm,payment } = expanded;
  const { selectedQuote } = useSelectedQuoteStore();
  const subtitle =
    'Con los que ' +
    selectedQuote.insurance.toUpperCase() +
    ' producirá tu póliza';

  const [additionalConfiguration, setAdditionalConfiguration] =
  useState<AdditionalConfig>({
    shouldBill: false,
    isDriverSameContractor: false,
  });

  const setFinishedCarForm = () => {
    setChecked((prev) => ({ ...prev, first: true }));
    setExpanded((prev) => ({ ...prev, car: false, contrator: true }));
  };

  const setFinishedContratorForm = () => {
    setChecked((prev) => ({ ...prev, second: true }));
    setExpanded((prev) => ({
      ...prev,
      contrator: false,
      address: false,
      dataContrator: true,
    }));
  };


  useEffect(() => {
    window.scroll(0, 0);
    //alert(expanded.payment)
    setExpanded({
      car: activeStep===0,
      contrator: activeStep===1,
      dataContrator:activeStep===2,
      confirm:activeStep===3,
      payment:activeStep===4,
    })
  }, [activeStep]);

  return (
    <Grid item>
      <Grid container paddingY={2} spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Box sx={{width:'auto',alignItems:'center'}}>
            <SteppersCustom
                title={[
                  "Datos del vehículo",
                  "Datos del conductor",
                  "Datos del Contratante",
                    "Resumen de tu compra",
                  "Método de pago"
                ]}
                activeStep={activeStep}
                steps={["Paso 1 de 5", "Paso 2 de 5", "Paso 3 de 5", "Paso 4 de 5","Paso 5 de 5"]}
            />
          </Box>
          <Box marginBottom={1}/>
          <CarData
            expanded={car}
            setExpanded={() => setFinishedCarForm()}
            setActiveStep={setActiveStep}
            setShowToast={setShowToast}
            setShowErrorToast={setShowErrorToast}
          />
          <ContratorData
            expanded={contrator}
            setExpanded={() => setFinishedContratorForm()}
            setActiveStep={setActiveStep}
            setShowErrorToast={setShowErrorToast}
            setAdditionalConfiguration={setAdditionalConfiguration}
          />
          <DataContrator
            additionalConfiguration={additionalConfiguration}
            expanded={dataContrator}
            setActiveStep={setActiveStep}
            setShowErrorToast={setShowErrorToast}
          />
          {
            confirm &&
              <ConfirmData expanded={confirm}
                           activeStep={activeStep}
                           setActiveStep={setActiveStep}  />
          }

          {
              payment &&
              <PaymentData expanded={payment}
                           activeStep={activeStep}
                           setActiveStep={setActiveStep}  />
          }

        </Grid>
      </Grid>
    </Grid>
  );
};
