import React from 'react';

import logoHdiFigma from '@assets/images/logoHdiFigma.svg';
import logoInterFigma from '@assets/images/logoInterFigma.svg';
import Grid from '@mui/material/Grid';
import newLogoCoppel from '@assets/images/newLogoCoppel.svg';
import club_de_proteccion from '@assets/images/club_de_proteccion.svg';
import { useTheme, useMediaQuery, Typography, Box } from '@mui/material';


import './styles.css';

interface Item {
  image: string;
  title: string;
}

const items: Item[] = [
  {
    title: 'HDI',
    image: logoHdiFigma,
  },
  {
    title: 'Coppel',
    image: newLogoCoppel,
  },
  {
    title: 'Inter',
    image: logoInterFigma,
  },
  {
    title: 'Club de Protección',
    image: club_de_proteccion,
  },
];


export const PartnersList: React.FC = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  return (
      <>
        <Typography
          style={{
            textAlign: isTablet ? "center" : "center",
            marginLeft: isTablet ? "0%" : "0px",
            fontWeight: 700,
            fontSize: isTablet ? "24px" : "28px",
            letterSpacing: isTablet ? "0.5px" : ""
          }}>{"¿Quién respalda este servicio?"}</Typography>
        <Box sx={{marginBottom: 3}} />

        {isTablet
        ?
          <div className="slider">
            <div className="slide-track">
              {items.map((item) => (
                <div className="slide">
                  <img src={item.image} alt={'icon-insurance'} />
                </div>
              ))}
              {items.map((item) => (
                <div className="slide">
                  <img src={item.image} alt={'icon-insurance'} />
                </div>
              ))}
              {items.map((item) => (
                <div className="slide">
                  <img src={item.image} alt={'icon-insurance'} />
                </div>
              ))}
              {items.map((item) => (
                <div className="slide">
                  <img src={item.image} alt={'icon-insurance'} />
                </div>
              ))}
              {items.map((item) => (
                <div className="slide">
                  <img src={item.image} alt={'icon-insurance'} />
                </div>
              ))}
            </div>
          </div>
        :
          <Grid
            container
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={12} md={8}>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                xs={12}
              >
                <Grid item xs={2.5} >
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                  >
                    <img alt='HDI' src={logoHdiFigma} />
                  </Grid>
                </Grid>
                <Grid item xs={2.5} alignItems='center' >
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                  >
                    <img alt='Coppel' src={newLogoCoppel} />
                  </Grid>
                </Grid>
                <Grid item xs={2.5} alignItems='center' >
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                  >
                    <img alt='Inter' src={logoInterFigma} />
                  </Grid>
                </Grid>
                <Grid item xs={2.5} alignItems='center' >
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                  >
                    <img alt='Club Protección' src={club_de_proteccion} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>}
      </>
  );
};
