import React, { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
  useMediaQuery,
} from '@mui/material';

type PlanProps = {
  plan: {
    title: string;
    details: string[];
  };
};

export const Plan: React.FC<PlanProps> = ({ plan }) => {
  const { palette } = useTheme();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const [expanded, setExpanded] = useState<boolean>(
    plan.title === 'Amplia' || isDesktop ? true : false
  );

  return (
    <Grid item xs={12} md={4} display={'flex'}>
      <Accordion
        elevation={0}
        style={{ borderRadius: 8, width: '100%' ,backgroundColor:'#238DC1', paddingLeft:0,
          paddingRight:0}}
        expanded={isTablet ? expanded : true}
        onChange={() => isTablet && setExpanded(!expanded)}
      >
        <Box sx={{padding:'0 10px 0px 10px'}}>
          <AccordionSummary
              expandIcon={isTablet ? <ExpandMore style={{color:'white'}} /> : null}
              aria-controls={plan.title + '-content'}
              id={plan.title + '-header'}
              sx={{
                "&:hover:not(.Mui-disabled)": {
                  backgroundColor: "transparent",
                },
                '&.Mui-focusVisible': {
                  backgroundColor: 'transparent',
                },
                '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-focusVisible': {
                  backgroundColor: 'transparent'
                },
                cursor: isTablet ? "pointer" : "auto",
                justifyContent:'center',
                width:'100%',
                alignItems:'center',
                borderBottom:isTablet ?  !expanded ? "": '0.5px solid': '0.5px solid',
                borderBottomColor: 'white',
                maxHeight: '35px',
                height: '35px',
                padding:0,
                margin:0
              }}>
            <Box sx={{width:'100%',
              height:'auto',
              display:'flex',flexDirection:'column',alignItems:'center'}}>
              <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{
                color: "var(--White, #FFF)",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "28px /* 140% */",
                letterSpacing: "0.25px",
              }}>
                {plan.title}
              </Typography>
              {/*<Box sx={{marginTop:1,display:{xs:'none',md:"flex"},width:'99%',height:1.1,backgroundColor:'#FFFFFF'}}/>*/}
            </Box>
          </AccordionSummary>
        </Box>

        <AccordionDetails>
          {plan.details.map((text, i) => (
            <div key={i} style={{ marginBottom: 10 }}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <CheckCircleIcon style={{ color: "#c0e6f2" }} />
                <div style={{ marginRight: 10 }} />
                <Typography sx={{color:'white',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px /* 150% */",
                }}>{text}</Typography>
              </Box>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
