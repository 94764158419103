import React from 'react';
import { Box } from '@mui/material';

import { Features } from './Features';
import { selectedInsurer } from '@api/features';
import { OrderSelect } from './OrderSelect';
import { useQuoteStore, useSelectedQuoteStore } from '@store/QuoteStore';

interface FeacturesListProps {
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeacturesList: React.FC<FeacturesListProps> = ({
  isMobile,
  isDesktop,
  isTablet,
  setShowToast,
}) => {
  //cotizacion selecionada
  const { selectedQuote } = useSelectedQuoteStore();
  let position = 0;

  if (selectedQuote.package === 'AMP') {
    position = 0;
  }
  if (selectedQuote.package === 'LIMIT') {
    position = 1;
  }
  if (selectedQuote.package === 'RC') {
    position = 2;
  }
  return (
    selectedQuote &&
    selectedQuote.available_packages &&
    selectedQuote.available_packages.length > 1 && (
      <Box
        style={{
          overflow: 'scroll',
          borderRadius: 8,
          margin: !isMobile ? 5 : 0,
          marginRight: !isMobile ? '24PX' : 0,
          marginTop: !isMobile ? '24PX' : 0,
          padding: !isMobile ? 16 : 1,
          width: `${isDesktop ? '65%' : '100%'}`,
          backgroundColor: 'white',
          height: !isMobile
            ? selectedQuote.available_packages[position].coverages_array
                .length === 7
              ? '740px'
              : '840px'
            : '50%',
        }}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginTop={2}
        borderRadius={2}
        padding={4}
      >
        <OrderSelect
          isMobile={isMobile}
          isDesktop={isDesktop}
          icon={selectedInsurer.logo}
          name={selectedInsurer.name}
          setShowToast={setShowToast}
        />
        <Box margin="30px 0px">
          {selectedQuote.available_packages[position]?.coverages_array.map(
            (feature: any, index: any) =>
              feature.sum_assured === '' ? null : (
                <Features
                  key={`feature-${index}`}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  logo={feature.logo}
                  title={feature.name}
                  descripcion={feature.descripcion}
                  titleTicket={feature.titleTicket}
                  numberTicket={feature.deductive}
                  feature={feature}
                />
              )
          )}
        </Box>
      </Box>
    )
  );
};
