import React, {useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import { SlideShowItem } from '@models/ui/SlideShowItem';
import FirstSlide from '@assets/images/firstBlueSlide.jpg';
import SecondSlide from '@assets/images/secondBlueSlide.jpg';
import { Plans } from '@screens/home/benefits/Plans';
import { Benefits } from '@screens/home/benefits/Benefits';
import { SlideShow } from '@screens/home/slideshow/SlideShow';
import { PartnersList } from '@screens/home/partners/PartnersList';
import backgroundCircle from '@assets/images/circles.png';
import { useIdStore } from '../store/UrlIdStore';

import { useTrackingStore } from '../store/TrackingStore';
import { useTokenStore } from '../store/TokenStore';
import { useParams } from 'react-router-dom';
import {getToken} from "@api/getToken";
import {InterInstance} from "@api/config";
import {usePolicyStore} from "@store/policyStore";
import Loading from "@components/Loading/Loading";
import {Box} from "@mui/material";

const items: SlideShowItem[] = [
  {
    title: 'Compara y contrata tu seguro',
    image: FirstSlide,
    complement: ' en un solo lugar',
    subtitle: 'Fácil, rápido y seguro',
  },
  {
    title: 'Encuentra un seguro de auto, moto y pickup',
    image: SecondSlide,
  },
];

type UrlParams = {
  id: string;
}

const useStyles = makeStyles({
  root: {
    paddingBottom: '40px',
    backgroundImage: `url(${backgroundCircle})`,
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundPositionY: '35%',
  },
});

export const Home: React.FC = () => {
  const { id: tmpId } = useParams<UrlParams>()
  const classes = useStyles();
  const history = useHistory();
  const [loading,setLoading] = useState(false)
  const { tracking, setDataTracking } = useTrackingStore();
  const { policy, addPolicyInfo } = usePolicyStore();

  const { setToken, token } = useTokenStore();
  const { setId } = useIdStore();

  //Datalayer
  useEffect(() => {
    //window.localStorage.clear();
    window.scroll(0, 0);
    setToken(token);
    setDataTracking(tracking);
  }, []);


  const verifyCoppelEmployeeId = async (id: string) => {
    setLoading(true)
    let tmpToken = token
    if(!tmpToken){
      tmpToken = await getToken()
    }
    const { data } = await InterInstance({
      method: 'GET',
      url: `/catalogs/coppel-workers/${id}`,
      headers: { Authorization: `Bearer ${tmpToken}` },
    }).catch(error => {
      console.log(error)
      setLoading(false)
      return error
    });

    if(data && data.success && data.allowed){
      const { data: { employee } } = data
      addPolicyInfo({
        ...policy,
        employee_id: employee.numeroempleado,
        employee_obj:employee
      })
      return data.success
    }
    return false
  }

  useEffect(()=>{
    if(tmpId)
      asyncLoad(tmpId)
  },[])
  const asyncLoad = async (id: string) => {
    if (policy.employee_id === ""){
      let response = await verifyCoppelEmployeeId(id)
      if (response){
        history.push('/customer-portal');
        setLoading(false)
      }else {
        setLoading(false)
         history.replace({pathname: '/'})
      }
    }
  }

  useEffect(()=>{
    if(tmpId){
      setId(tmpId)
    }
  }, [])




  return (
    <div className={classes.root}>
      {
        loading  ?
            <>
              <Loading open={loading} handleClose={() => true} />
              <Box sx={{width:'100%',height:'100vh'}}/>
            </>

          :
        <>
          <SlideShow items={items} />
          <PartnersList />
          <Plans />
          <Benefits />

        </>
      }

    </div>
  );
};
