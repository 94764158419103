import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Paper,
  Box,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import google from '@assets/images/GooglePlayLogo.svg';
import apple from '@assets/images/AppStoreLogo.svg';

type BenefitCardProps = {
  hasDivider?: boolean;
  style?: React.CSSProperties | undefined;
  item: {
    title: string;
    subtitle: string;
    image: string;
    extras: number;
  };
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ isTablet }: any) => (isTablet ? 'start' : 'center'),
    flexDirection: ({ isTablet }: any) => (isTablet ? 'row' : 'column'),
  },
});

export const BenefitCard: React.FC<BenefitCardProps> = ({
  item,
  style = undefined,
  hasDivider = false,
}) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const classes = useStyles({ isTablet });

  return (
    <Grid item xs={12} md={4}  display={!isTablet ? 'flex' : ''} sx={{height:{xs:'auto',md:'292px'} }} >
      <Paper
        elevation={0}
        style={
          isTablet
            ? {
                padding: isTablet ? 24 : 32,
                width: !isTablet ? '100%' : '',
                ...style,
              backgroundColor:'transparent'
              }
            : {
                padding: isTablet ? 24 : 32,
                borderRadius: 8,
                width: !isTablet ? '100%' : '',
                  backgroundColor:'transparent'

              }
        }
      >
        <div className={classes.root} style={{gap:'4px'}}>
          <Box
            component={'img'}
            src={item.image}
            sx={{
              width: '72px',
              height: '72px',
            }}
            alt={item.title}
          />
          <div style={{ marginBottom: 10 }} />
          <div style={{ marginRight: isTablet ? 16 : 0 }} />
          <div
            style={{
              flexDirection: 'column',
              justifyContent: isTablet ? 'start' : 'center',
            }}
          >
            <Typography
              sx={{
                  alignSelf: "stretch",
                  color: "var(--Black, #212121)",
                  textAlign: isTablet ? 'start' : 'center',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px /* 140% */",
                  letterSpacing: "0.25px",
                fontSize: {
                  xl: 20,
                  lg: 20,
                  md: 20,
                  sm: 20,
                  xs: 20,
                },
              }}
            >
              {item.title}
            </Typography>
            <div style={{ margin: 18 }} />
            <Typography
              maxWidth={item.extras === 1 ? '480px' : 'none'}
              sx={{
                  alignSelf: "stretch",
                  color: "var(--Black, #212121)",
                  textAlign:isTablet ? 'start' : 'center',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px /* 150% */",
                fontSize: {
                  xl: 16,
                  lg: 16,
                  md: 16,
                  sm: 16,
                  xs: 16,
                },
              }}
            >
              {item.subtitle}
            </Typography>
            {item.extras === 1 && (
              <Box
                display={'flex'}
                marginTop={2}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                style={{ gap: '16px' }}
              >
                <Box
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=grut.inter.mx', '_blank')}
                  component={'img'}
                  src={google}
                  sx={{
                    width: '100px',
                  }}
                  alt={'Google play'}
                  style={{ objectFit: 'cover', borderRadius: 8, cursor: 'pointer' }}
                />
                <Box
                  onClick={() => window.open('https://apps.apple.com/mx/app/intermx/id1548367767?l=en', '_blank')}
                  component={'img'}
                  src={apple}
                  sx={{
                    width: '100px',
                  }}
                  alt={'Appstore'}
                  style={{ objectFit: 'cover', borderRadius: 8, cursor: 'pointer' }}
                />
              </Box>
            )}
          </div>
        </div>
      </Paper>
      {isTablet && hasDivider && (
        <Box marginX={1}>
          {' '}
          <Divider />{' '}
        </Box>
      )}

        {!isTablet && hasDivider && (
            <Divider orientation="vertical" variant="middle" flexItem />
        )}
    </Grid>
  );
};
