import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useDriverStore } from '@store/driverInfoStore';
import { calculateAge } from '@utils/utilityFunctions';
import React from 'react';
import DriverHeader from './DriverHeader';

interface FullWidth {
  fullWidth?: boolean;
}

function DriverSelectInfo(props: FullWidth) {
  const { driver } = useDriverStore();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const DriverInfo = [
    {
      title: 'Nombre',
      value: driver.name,
    },
    {
      title: 'C.P.',
      value: driver.codigoPostal,
    },
    {
      title: 'Telefono',
      value: driver.telefono,
    },
    {
      title: 'Sexo de nacimiento',
      value: driver.genero,
    },
    {
      title: 'Edad',
      value: calculateAge(driver.fechaNacimiento),
    },
    {
      title: 'E-mail',
      value: driver.email,
    },
  ];

  return (
    <Grid
      item
      md={props.fullWidth ? 12 : 8}
      lg={props.fullWidth ? 12 : 5}
      xl={ props.fullWidth ? 12 : 5}
      style={{ border: '2px solid transparent', order: `${isTablet ? -1 : 1}` }}
    >
      <DriverHeader />
      <Box
        padding={0.7}
        height={'105px'}
        marginTop={2}
        borderRadius={2}
        sx={{
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
            boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid
          container
          spacing={0}
          rowSpacing={0.5}
          columnSpacing={{ xs: 1, sm: 2, md: 0 }}
          style={{ width: '100%', height: '100%' }}
        >
          {DriverInfo.map((i: any, index) => (
            <Grid item xs={4} key={`driverinfo + ${index}+${Math.random}`}>
              <InfoItem info={i} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
}

interface InfoProps {
  info: {
    title: string;
    value: string;
  };
}

const InfoItem: React.FC<InfoProps> = ({ info }) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  return (
    <Box display={'flex'} flexDirection={'column'} style={{ height: '100%' }}>
      <Typography
        variant={'caption'}
        fontSize={'12px'}
        style={{
          fontWeight: 'bold',
          color: '#424242',
        }}
        textAlign={'start'}
      >
        {info.title === 'Sexo de nacimiento' && isTablet
          ? 'S. de N.'
          : info.title}
      </Typography>
      <Typography
        variant={'caption'}
        fontSize={'13px'}
        style={{
          fontWeight: 'normal',
          color: '#424242',
        }}
        textAlign={'start'}
      >
        { info.title === 'E-mail' ? (info.value.length > 13
          ? info.value.substring(0, 13) + '...'
          : info.value) : (info.value.length > 15
          ? info.value.substring(0, 15) + '...'
          : info.value)}
      </Typography>
    </Box>
  );
};

export default DriverSelectInfo;
