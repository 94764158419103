import React, { useState } from 'react';
import { PolicyListSlideItem } from '@screens/customerPortal/policyListSlider/PolicyListSlider';
import { Box, Button, Typography } from '@mui/material';
import {ReactComponent as IconDownload} from '@assets/images/Download.svg';
import { useTokenStore } from '@store/TokenStore';
import { getToken } from '@api/getToken';
import { getPoliza } from '@api/getPoliza';
import CircularProgress from '@mui/material/CircularProgress';

type PolicyItemProps = {
  item: PolicyListSlideItem
}

const DownloadPDF = (link: string) => {

  let element = document.createElement('a');
  element.setAttribute('href', link);
  element.setAttribute('target', '_blank')
  element.setAttribute('download', 'download')
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

const PolicyItem: React.FC<PolicyItemProps> = ({ item }) => {

  const { token } = useTokenStore();
  const [loading,setLoading] = useState(false)
  const DownloadActionPDF = async (PolicyId:string) => {
    let tmpToken = token
    if(!tmpToken){
      tmpToken = await getToken()
    }
    setLoading(true)
    let dataInfo = {
      Poliza: PolicyId,
      insurance: "hdi",
      tracking_id: ""
    }
    let dataPoliza = await getPoliza(tmpToken, dataInfo);

    if(dataPoliza && dataPoliza.success){
      setTimeout(() => {
        DownloadPDF(dataPoliza?.data?.policy_url)
        setLoading(false)
      },3000)

    }else {
      setLoading(false)

    }
    setLoading(false)
  }

  return (
    <Box sx={{
      display: 'flex',
      width: { xs: '100%', md: '461px' },
      height:{xs:'430px', md:'396.172px'},
      padding: { xs: '16px 0px 10px 0px', md: '16px 8px' },
      flexDirection: 'column',
      alignItems: 'center',
      gap: '12px',
      flexShrink: 0,
      borderRadius: '16px',
      background: '#F1F9FC'
    }}>
      <Box sx={{
        flexDirection: 'row', width: '90%',
        display: 'flex',
        marginTop: 5,
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          color: 'var(--Black, #212121)',
          fontFeatureSettings: '\'clig\' off, \'liga\' off',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px /* 150% */'
        }}>{'Número de póliza'}</Box>
        <Box sx={{
          color: 'var(--Black, #212121)',
          textAlign: 'right',
          fontFeatureSettings: '\'clig\' off, \'liga\' off',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px /* 150% */'
        }}>{item.numero_poliza}</Box>
      </Box>

      <Box sx={{
        flexDirection: 'row', width: '90%',
        display: 'flex',
        marginTop: 4,
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          color: 'var(--Black, #212121)',
          fontFeatureSettings: '\'clig\' off, \'liga\' off',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px /* 150% */'
        }}>{'Nombre del contratante'}</Box>

        <Box sx={{
          color: 'var(--Black, #212121)',
          textAlign: 'right',
          fontFeatureSettings: '\'clig\' off, \'liga\' off',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px /* 150% */'
        }}>{item.nombre_contratante}</Box>
      </Box>

      <Box sx={{
        flexDirection: 'row', width: '90%',
        display: 'flex',
        marginTop: 4,
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          color: 'var(--Black, #212121)',
          fontFeatureSettings: '\'clig\' off, \'liga\' off',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px /* 150% */'
        }}>{'Fecha inicio de vigencia'}</Box>

        <Box sx={{
          color: 'var(--Black, #212121)',
          textAlign: 'right',
          fontFeatureSettings: '\'clig\' off, \'liga\' off',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px /* 150% */'
        }}>{item.fecha_inicio}</Box>
      </Box>


      <Box sx={{
        flexDirection: 'row', width: '90%',
        display: 'flex',
        marginTop: 4,
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          color: 'var(--Black, #212121)',
          fontFeatureSettings: '\'clig\' off, \'liga\' off',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px /* 150% */'
        }}>{'Fecha fin de vigencia'}</Box>

        <Box sx={{
          color: 'var(--Black, #212121)',
          textAlign: 'right',
          fontFeatureSettings: '\'clig\' off, \'liga\' off',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px /* 150% */'
        }}>{item.fecha_fin}</Box>
      </Box>

      <Button  variant="text" onClick={()=>{DownloadActionPDF(item.numero_poliza)}} sx={{mt:{xs: 0,md:4},textTransform:'none'}}  endIcon={ loading  ?   <CircularProgress sx={{color:'#004876'}} size={'23px'} /> : <IconDownload />}>
          <Typography
            sx={{
              color: 'var(--club-1, #004876)',
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              maxWidth: '170px'
            }}
          >
          Descargar póliza
          </Typography>
        </Button>

    </Box>
  );
};
export default PolicyItem;
