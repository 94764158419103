import React from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { Plan } from '@screens/home/benefits/Plan';

interface PlanModel {
  title: string;
  details: string[];
}

const plans: PlanModel[] = [
  {
    title: 'Amplia',
    details: [
      'Daños materiales',
      'Robo Total',
      'Responsabilidad civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial',
      'Responsabilidad Civil USA y Canadá',
      'Muerte Accidental del Conductor',
    ],
  },
  {
    title: 'Limitado',
    details: [
      'Robo Total',
      'Responsabilidad civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial',
      'Responsabilidad Civil USA y Canadá',
      'Muerte Accidental del Conductor',
    ],
  },
  {
    title: 'Responsabilidad Civil',
    details: [
      'Responsabilidad civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial',
      'Responsabilidad Civil USA y Canadá',
      'Muerte Accidental del Conductor',
    ],
  },
];

export const Plans: React.FC = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  return (
    <Container maxWidth={'lg'}>
      <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
        <Box sx={{
          marginTop:{xs:2,md:5},
          display: "flex",
          width: {xs:'95%',md:"1144px"},
          justifyContent: "center",
          alignItems: "center",
          height:1.3, backgroundColor:'#e2e2e2'}}/>
      </Box>
      <Box sx={{ flexGrow: 1 }} style={{ marginTop: 50, marginBottom: 50 }}>
        <Typography
          fontWeight={'700'}
          style={{
            fontSize: isTablet ? "24px" : "28px",
            marginBottom: 50 }}
          textAlign={isTablet ? 'center' : 'center'}
        >
          {'¿Cuáles son los planes disponibles?'}
        </Typography>
        <Grid container spacing={4} alignItems={'stretch'}>
          {plans.map((plan, i) => (
            <Plan key={i} plan={plan} />
          ))}
        </Grid>
      </Box>
      <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
        <Box sx={{
          marginTop:{xs:0,md:1},
          display: "flex",
          width: {xs:'95%',md:"1144px"},
          justifyContent: "center",
          alignItems: "center",
          height:1.3, backgroundColor:'#e2e2e2'}}/>
      </Box>
    </Container>
  );
};
