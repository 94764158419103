import { InterInstance } from '@api/config';

export const getToken = async () => {
  try {
    const {
      data: { data },
    } = await InterInstance({
      method: 'post',
      url: `/authorization/token`,
      data: {
        client_id: "UciRTgw4fSbe5lkiQjoxVNGsw36YBci8",
        client_secret: "of1EOL9jaFTkt8hHpXcQnF3TACjffpxfr-SsA20zTVpOVyNkaAPNMSdXlygxjrs2"
       },
    });
    return data.access_token;
  } catch (e) {
    return e;
  }
};
