import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import backgroundCircle from '@assets/images/circles.png';

import { Box, Button, FormControl, Grid, InputAdornment, OutlinedInput, Toolbar, Typography } from '@mui/material';
import { usePolicyStore } from '@store/policyStore';
import { useTokenStore } from '@store/TokenStore';
import { useIdStore } from '@store/UrlIdStore';
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import PolicyListSlider, { PolicyListSlideItem } from '@screens/customerPortal/policyListSlider/PolicyListSlider';
import Coppel from '@assets/images/Coppel.png';
import Asesor from '@assets/images/Asesor.svg';
import Club from '@assets/images/club_de_proteccion.svg';
import { getToken } from '@api/getToken';
import { InterInstance } from '@api/config';

const useStyles = makeStyles({
  root: {
    paddingBottom: '40px',
    /* backgroundImage: `url(${backgroundCircle})`,*/
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundPositionY: '35%'
  }
});

const polizas = [{
  id: 1,
  numero_poliza: '001',
  nombre_contratante: 'Pruebas Test Test',
  url: 'ksdnfknqiweflfl',
  fecha_inicio: '04-05-2023',
  fecha_fin: '04-05-2024'
},
  {
    id: 2,
    numero_poliza: '002',
    nombre_contratante: 'Pruebas Test TestDos',
    url: 'ksdnfknqiweflfldos',
    fecha_inicio: '04-06-2023',
    fecha_fin: '04-06-2024'
  }

];

export const CustomerPortal: React.FC = () => {
  const { token } = useTokenStore();
  const [loading, setLoading] = useState(true);
  const [employee,setEmployee]= useState<any>(null)
  const [policiesData, setPoliciesData] = useState<PolicyListSlideItem[]>([]);

  const classes = useStyles();
  const history = useHistory();
  const { policy,addPolicyInfo,removePolicyInfo } = usePolicyStore();
  const { id,setId } = useIdStore();

 /* const handleClick = () => {

  };*/
  const getPolicies = async (id: string) => {
    setLoading(true);
    let tmpToken = token;
    if (!tmpToken) {
      tmpToken = await getToken();
    }
    const { data } = await InterInstance({
      method: 'GET',
      url: `/catalogs/coppel-workers/${id}/policies`,
      headers: { Authorization: `Bearer ${tmpToken}` }
    }).catch(error => {
      console.log(error);
      return error;
    });

    if (data && data.success) {
      const { data: { policies } } = data;
      setPoliciesData(policies);
    } else {
      setPoliciesData([]);
    }
    setLoading(false);
  };


  useEffect(() => {
    if (id) {
      setEmployee({id:id,...policy.employee_obj})
      getPolicies(id);
    }
    if (policy.employee_obj?.numeroempleado === '') {
      history.push('/');
    }
  }, [id]);

  const newQuote =()=>{
    sessionStorage.clear();
    removePolicyInfo();
    addPolicyInfo({
      ...policy,
      employee_id: employee?.numeroempleado,
    });
    setId(employee?.id);
    history.push('/');
  }



  return (
    <div className={classes.root}>
      <Toolbar variant="dense" disableGutters sx={{ height: { xs: '140px', md: '120px' } }} />
      <Box sx={{ height: 'auto', padding: 2, maxWidth: '1140px', margin: 'auto' }}>
        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Box sx={{ width: { xs: '100%', md: '461px' }, height: '550px' }}>
            <Box sx={{ padding: '8px', mb: 1 }}>
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '24px'
                }}
              >
                {'Nombre del colaborador'}
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              borderRadius: '8px',
              border: '1px solid var(--black-25, #C7C7C7)',
              background: 'var(--White, #FFF)',
              alignItems: 'center',
              padding: '16px'
            }}>
              {policy.employee_obj.nombreempleado + ' ' + policy.employee_obj.apellidopaternoempleado + ' ' + policy.employee_obj.apellidomaternoempleado}</Box>
            <Grid>
              <Box sx={{ position: 'relative' }}>
                <Box sx={{
                  position: 'absolute',
                  display: 'flex', flexDirection: 'column', width: '275px', top: '270px'
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    padding: '16px 16px 0 16px'
                    /*background:'red'*/
                  }}>

                    <Typography
                      style={{
                        color: 'var(--White, #FFF)',
                        fontSize: '20px',
                        textAlign: 'left',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        letterSpacing: '-0.36px'
                      }
                      }
                    >
                      Compara y contrata el seguro de tu vehículo en un sólo lugar
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: '10px' }}>
                    <Button
                      variant="contained"
                      onClick={() => newQuote()}
                      size={'medium'}
                      style={{
                        textTransform: 'none',
                        height: '56px',
                        borderRadius: '8px',
                        width: '255px',
                        background: '#238DC1',
                        gap: '10px'
                      }}
                    >
                      <span style={{ fontSize: '16px', fontStyle: 'normal', fontWeight: '600' }}>Deseo cotizar</span>
                    </Button>
                  </Box>
                </Box>
                <Box
                  component={'img'}
                  src={Coppel}
                  sx={{ width: { xs: '100%', md: '461px' }, height: '406px', mt: 5 }}
                />
              </Box>
            </Grid>
          </Box>

          <Box sx={{
            width: { xs: 'auto', md: '655px' },
            padding: { xs: 3, md: 0 },
            display: 'flex',
            flexDirection: 'column',
            height: '550px',
            background: '#FFF',
            borderRadius: '16px',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={{ xs: 2, sm: 52, md: 26 }}
              /*sx={{maxWidth:'461px'}}*/
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '24px',
                  color: '#212121',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '28px',
                  textAlign: 'center'
                }}
              >
                Mis pólizas
              </Typography>

              <Box
                component={'img'}
                src={Club}
                sx={{ width: { md: '113px' }, height: '40px', mt: 5 }}
              />
            </Stack>
            <Box sx={{ mt: 3 }} />
            <PolicyListSlider items={policiesData} loading={loading} />
          </Box>
        </Stack>
        <Box sx={{
          display: 'flex',
          background: '#004876',
          mt: 5,
          height: '55px',
          borderRadius: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: '1px', sm: '16px', md: '16px' },
          padding: { xs: '.3em' }
        }}>
          <Box
            component={'img'}
            src={Asesor}
            sx={{ width: { xs: '25px', md: '30px' }, height: '39px', margin: { xs: '12px' } }}
          >

          </Box>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: { xs: 13, sm: 14, md: 20 },
              color: '#FFF',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
              textAlign: 'center'
            }}
          >
            En caso de siniestro o requerir asistencia vial/legal, comunícate al <b>800-019-6000</b>
          </Typography>
        </Box>
      </Box>

    </div>
  );
};
