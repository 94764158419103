import { useContext } from 'react';
import React from 'react';
import { Box, Button } from '@mui/material';
import { ZustandContext } from '@store/modalStore';
import { ModalPay } from '@modals/ModalPay';

import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';

import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { usePolicyStore } from '@store/policyStore';
import { useCPStore } from '@store/cpStore';
import { useHistory } from 'react-router-dom';
import { getPayment } from '@api/getPayment';
import { getPoliza } from '@api/getPoliza';
import { getRecibo } from '@api/getRecibo';
import { useCarListStore } from '@store/carListStore';
import { getTracking } from '@api/getTracking';
import Loading from '@components/Loading/Loading';

type Props = {
  isDisabled: boolean;
  setActiveStep?:(id:number)=> void;
}

export const ButtonConfirm: React.FC<Props> = ({ isDisabled ,setActiveStep}) => {
  const { show, hide } = useContext(ZustandContext);
  const history = useHistory();

  const { car: Car } = useCarStore();
  const { driver } = useDriverStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { token } = useTokenStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { carList } = useCarListStore();
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    policy.stage = '3. Contratación (Coche)';
    policy.sub_stage = '6. Datos Bancarios';
    policy.state_code = 'Activa';
    policy.status_code = 'En curso';
    addPolicyInfo({ ...policy });
    let initialData = {
      id: tracking.id,
      business: 'coppel-hdi',
      data: {
        car: Car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
        carList: carList,
      }
    };
    getTracking(token, initialData);
    upCRM(
      token,
      Car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );
    if (selectedQuote.insurance !== 'zurich') {
      if (setActiveStep){
        setActiveStep(4)
      }

     // show(ModalPay, { onClose: hide });
    }
  };

  return (
    <Box marginTop={3}>
      <Loading open={loading} handleClose={() => true} />
      <Button
        disabled={isDisabled}
        variant="contained"
        onClick={handleClick}
        size={'medium'}
        style={{
          textTransform: 'none',
          height: '55px',
          borderRadius: '10px',
          width: '100%',
        }}
      >
        Pagar
      </Button>
    </Box>
  );
};
