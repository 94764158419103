import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';

import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { usePolicyStore } from '@store/policyStore';
import { useCPStore } from '@store/cpStore';
import { useCarListStore } from '@store/carListStore';
import { getTracking } from '@api/getTracking';
import Vigency from '@options/Vigency';

interface QuotationProps {
  isMobile: boolean;
  isDesktop: boolean;
}

export const Quotation: React.FC<QuotationProps> = ({
  isMobile,
  isDesktop
}) => {
  const history = useHistory();
  const { selectedQuote } = useSelectedQuoteStore();
  const { payment_option } = useGetQuotePayment();

  const { car } = useCarStore();
  const { driver } = useDriverStore();
  const { token } = useTokenStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { carList } = useCarListStore();


  const goToHiring = () => {
    policy.stage = '2. Cotización (Coche)';
    policy.sub_stage = '3. Elección';
    policy.state_code = 'Activa';
    policy.status_code = 'En curso';
    addPolicyInfo({ ...policy });
    let initialData = {
      id: tracking.id,
      business: 'coppel-hdi',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
        carList: carList,
      },
    };
    getTracking(token, initialData);
    upCRM(
      token,
      car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );
    history.push('/hiring');
  };

  let totalNumber = 0;
  let position = 0;
  let planRC;
  if (selectedQuote.package === 'AMP') {
    position = 0;
  }
  if (selectedQuote.package === 'LIMIT') {
    position = 1;
  }
  if (selectedQuote.package === 'RC') {
    position = 2;
    planRC = 'Plan Responsabilidad Civil';
  }
  selectedQuote.available_packages[position]?.receipts.map((item: any) => {
    const number = Number(item?.total_premium);
    totalNumber += number;
  });

  let totalPayment = totalNumber.toString();


  return (
    <Box
      style={{
        borderRadius: 8,
        margin: `${!isMobile ? '30px 0px 0px' : '100px 0px 0px'}`,
        padding: 0,

        width: isMobile ? '100%' : '35%',
      }}
    >
      <Vigency />
      <Box
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginTop="-10px"
        height="auto"
        borderRadius={2}
        padding={4}
        style={{
          boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
          backgroundColor: 'white' }}
      >
        <Box margin="0px 0px 20px 0px">
          <Typography fontWeight="700" fontSize="20px">
            Total a pagar
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          margin="0px 0px 20px 0px"
        >
          <Typography fontWeight="400" fontSize="14px">
            {planRC || policy.package_label}:
          </Typography>
          <Typography fontWeight="600" fontSize="16px">
            $
            {parseFloat(parseFloat(totalPayment).toFixed(2)).toLocaleString(
              'es-MX',
              { minimumFractionDigits: 2 }
            )}
          </Typography>
        </Box>
        <Payment
          paymentMode={policy.payment_label}
          payResults={selectedQuote.available_packages[position]?.receipts}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom={isDesktop ? '19px' : '15px'}
        ></Box>
        <hr
          style={{
            width: '100%',
            margin: '0px',
            border: '1px solid lightgray',
          }}
        />
        <TotalPayment payment={totalPayment} payment_option={payment_option} />

        <CustomButton
          onClick={goToHiring}
          color={'primary'}
          variant={'contained'}
          text="Pagar"
          style={{ height: 56, width: '100%', textTransform: 'none' }}
        />
      </Box>
    </Box>
  );
};

export const Payment = ({ paymentMode, payResults }: any) => {
  if (paymentMode === 'Anual') return null;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      margin="0px 0px 20px 0px"
    >
      <Typography fontWeight="400" fontSize="14px">
        Primer pago de
        <ul
          style={{
            margin: 0,
          }}
        >
          <li style={{ color: '#8F8F8F', fontWeight: 400 }}>
            {paymentMode === 'Semestral'
              ? 'Más un pago de'
              : `Más ${payResults.length - 1} pagos de`}
          </li>
        </ul>
      </Typography>
      <Typography fontWeight="600" fontSize="16px">
        <span style={{ marginLeft: '19px' }}>
          + $
          {parseFloat(
            parseFloat(payResults[0].total_premium).toFixed(2)
          ).toLocaleString('es-MX', {
            minimumFractionDigits: 2,
          })}
        </span>
        <ul
          style={{
            margin: 0,
          }}
        >
          <div
            style={{
              color: '#8F8F8F',
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            + $
            {parseFloat(
              parseFloat(
                payResults[1]
                  ? payResults[1].total_premium
                  : payResults[0].total_premium
              ).toFixed(2)
            ).toLocaleString('es-MX', {
              minimumFractionDigits: 2,
            })}
          </div>
        </ul>
      </Typography>
    </Box>
  );
};

export const TotalPayment = ({ payment, payment_option }: any) => {
  let isMobile = false;
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        margin={`${!isMobile ? '25px 0px 15px' : '25px 0px 30px'}}`}
      >
        <Typography>Costo anual:</Typography>
        <Typography fontSize="20px" fontWeight="700">
          $
          {parseFloat(parseFloat(payment).toFixed(2)).toLocaleString('es-MX', {
            minimumFractionDigits: 2,
          })}
        </Typography>
      </Box>
    </>
  );
};
