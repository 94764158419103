import hdi from '@assets/images/hdi.png';
import {Box, Typography} from '@mui/material';


const VehicleNotFound = () => {
    return (
        <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px'
        }}
        >
            {/*<div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          height: '150px'
        }}>
          <img
              src={hdi}
              width="150px"
              alt="logo-hdi"
          />
        </div>*/}
            <Box sx={{maxWidth: '461px', textAlign: 'center'}}>
                <Typography sx={{
                    color: "var(--black-75, #595959)",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "24px",
                    fontStyle: "normal",
                    lineHeight: "normal",
                }}>
                    Por el momento este vehículo no se puede asegurar con HDI,
                    comunícate al <span style={{textDecoration: 'none', fontWeight: 700}}>55-89-79-09-80</span> para
                    poder brindarte una solución.
                </Typography>
            </Box>
        </div>
    )
}

export default VehicleNotFound
