import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Box, Typography, TextField, Theme, Button} from '@mui/material';
import {ExpandMore, Info, MailOutline, Edit} from '@mui/icons-material';

//import Assets
import warning from '@assets/images/warning.svg';
import {usePolicyStore} from '@store/policyStore';
import ReactTooltip from 'react-tooltip';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    aseguradotooltip: {
        width: '25%',
        ['@media (max-width:780px)']: {
            width: '80%'
        }
    },
    plantooltip: {
        width: '25%',
        ['@media (max-width:780px)']: {
            width: '80%'
        }
    },
}));

export const PolicyNumber = () => {
    const [isShow, setIsShow] = useState(false);
    const {policy, addPolicyInfo} = usePolicyStore();
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            sx={{
                gap: {xs: '10px', md: '40px'},
                flexDirection: {xs: 'column-reverse',sm:'row', md: 'row'}
            }}
        >
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent="space-between"
                sx={{
                    display: "flex",
                    width: {xs: '92%', md: "364px"},
                    height: "10px",
                    padding: "16px",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "10px",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
                }}
            >
                <Typography fontSize={'14px'} lineHeight={'24px'} sx={{
                }}>
                    Número de cotización : &nbsp; <b>{policy.policyNumber}</b>
                </Typography>
                <Info
                    color="primary"
                    data-for='noCoti'
                    data-tip=""
                    data-iscapture="true"
                    sx={{cursor: 'pointer'}}
                />
                <ReactTooltip id='noCoti' type="light" place="left" backgroundColor='#EBF8FB'
                              className={classes.aseguradotooltip}>
                    <Box
                        padding={2}
                        marginTop={2}
                        borderRadius={2}
                        style={{backgroundColor: '#EBF8FB'}}
                    >
                        <Typography
                            textAlign={'start'}
                            fontWeight={'bold'}
                            fontSize={16}
                        >
                            Número de cotización
                        </Typography>
                        <Typography
                            textAlign={'start'}
                            fontWeight={'normal'}
                            fontSize={13}
                        >
                            ¡No pierdas este número! Te servirá para que, cuando quieras seguir tu proceso de
                            contratación, no tengas que llenar todos tus datos otra vez.
                        </Typography>
                    </Box>
                </ReactTooltip>
            </Box>

            <Button
                onClick={() => {
                    history.push('/');
                }}
                variant="outlined"
                sx={{
                    width: {xs: '100%',sm:'250px', md: "180px"},
                    height: "38px",
                    textTransform: 'none',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '10px',
                }}
            >
                Nueva cotización
            </Button>

            {/* <TextField
        disabled
        fullWidth
        size="small"
        style={{ borderRadius: '10px ' }}
        value={policy.policyNumber}
        onChange={({ target: { value } }) =>
          addPolicyInfo({ ...policy, policyNumber: value })
        }
      />*/}
        </Box>
    );
};
