import React, { useContext } from 'react';
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { ZustandContext } from '@store/modalStore';
import { ModalChangeCP } from '@modals/ModalChangeCP';

export const DetailHeader = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const { show, hide } = useContext(ZustandContext);
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography
        variant="subtitle1"
        style={{ fontWeight: 'bold' }}
        textAlign="start"
        fontSize={isTablet ? 15 : 16}
      >
        Detalle
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ fontWeight: 'bold' }}
        textAlign="start"
        fontSize={isTablet ? 10 : 15}
      >
        <IconButton
          onClick={() => show(ModalChangeCP, { onClose: hide, isDetail: true })}
        >
          <EditIcon style={{ color: '#004876' }} />
        </IconButton>
      </Typography>
    </Box>
  );
};
