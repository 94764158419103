import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme, Typography } from '@mui/material';
import { ZustandContext } from '@store/modalStore';
import ReactTooltip from 'react-tooltip';
import { Edit, Info } from '@mui/icons-material';
import { ModalChangeCP } from '@modals/ModalChangeCP';

interface DataInfo {
  title: string;
  description: string;
}

const dataInfo: DataInfo[] = [
  {
    title: 'Conductor asegurado',
    description:
      'Los datos del conductor asegurado nos permiten encontrar las mejores opciones para ti. Si los modificas, podrían cambiar las opciones.',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  aseguradotooltip: {
    width: '25%',
  },
  plantooltip: {
    width: '25%',
  },
}));

const DriverHeader = () => {
  const classes = useStyles();
  const { show, hide } = useContext(ZustandContext);

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        marginTop={0}
        justifyContent={'space-between'}
      >
        <Typography
          variant={'caption'}
          fontSize={'14px'}
          style={{
            fontWeight: 'bold',
            color: '#424242',
          }}
          textAlign={'start'}
        >
          {'Conductor asegurado'}
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={{gap:'24px'}}>
          <Info
              color="primary"
              data-for="c1"
              data-tip=""
              data-iscapture="true"
              sx={{ cursor: 'pointer' }}
          />
          <Edit
            onClick={() => show(ModalChangeCP, { onClose: hide })}
            sx={{ cursor: 'pointer',color:'#000' }}
          />

        </Box>
      </Box>
      <ReactTooltip
        id="c1"
        type="light"
        place="bottom"
        backgroundColor="#EBF8FB"
        className={classes.aseguradotooltip}
      >
        <Box
          padding={2}
          marginTop={2}
          borderRadius={2}
          style={{ backgroundColor: '#EBF8FB' }}
        >
          <Typography textAlign={'start'} fontWeight={'bold'} fontSize={16}>
            {dataInfo[0].title}
          </Typography>
          <Typography textAlign={'start'} fontWeight={'normal'} fontSize={13}>
            {dataInfo[0].description}
          </Typography>
        </Box>
      </ReactTooltip>
    </>
  );
};

export default DriverHeader;
