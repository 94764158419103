import React from 'react';
import { Box, Stack, Typography } from '@mui/material';



function Vigency({ isPayment= false }) {

    // @ts-ignore
    function validarFechaEnRango(){
        const  fecha =new Date();
        const day = fecha.getDate();
        const mes = fecha.getMonth();
        if(day <= 20 && (mes + 1) === 2){
            return true;
        }else{
            return false;
        }

    }


    const formattedDate: Date = new Date();
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };
    const vigencyBegin: string = formattedDate.toLocaleString('es-MX', options);
    const nextYear = new Date(formattedDate);
    nextYear.setFullYear(formattedDate.getFullYear() + 1);
    const vigencyEnd: string = "21/03/2025"


    return (
        <Box sx={{
            width:{xs:'auto',md: isPayment ? '100%':'auto'},
            margin:{xs:"24px 0px 20px 0px",md:"0px 0px 20px 0px"},

        }}>
            <Typography fontWeight="700" fontSize="16px">
                Vigencia
            </Typography>
            <Box sx={{
                width:{xs:'auto',md: isPayment ? '100%':'auto'},
                display: "flex",
                padding: "24px 16px",
                marginTop:{xs:"4px"},
                flexDirection: "column",
                gap: "24px",
                borderRadius: "8px",
                background: "var(--White, #FFF)",
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
            }}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontWeight="400" fontSize="18px">
                        Inicio:
                    </Typography>
                    <Typography fontWeight="400" fontSize="18px">
                        {`${vigencyBegin}`}
                    </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontWeight="400" fontSize="18px">
                        Término:
                    </Typography>
                    <Typography fontWeight="400" fontSize="18px">
                        {validarFechaEnRango() === true ? `${nextYear.toLocaleString('es-MX', options)}` : `${vigencyEnd}`}
                    </Typography>
                </Stack>
            </Box>

        </Box>
    )
}
export default Vigency;