import React from "react";
import {useMediaQuery} from "@mui/material";
import {CustomTypography} from "@components/Label/Label";

type SteppersProps = {
    title: string[];
    activeStep: number;
    steps: string[];
}

const SteppersCustom: React.FC<SteppersProps> = ({
                                                       title,
                                                       activeStep,
                                                       steps,
                                                   }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const active =  activeStep;


    return <React.Fragment>
        {title[active] ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
            gap: '1rem'
        }}>
            <CustomTypography
                item={title[active][0].toUpperCase() + title[active].slice(1).toUpperCase()}
                style={{
                    display: 'block',
                    fontSize: !matches ? '16px' : '16px',
                    textAlign: 'center',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    alignSelf: 'stretch',
                    // padding: !matches ? '0 1rem' : "",
                    // textWrap: !matches ? 'balance' : ""
                }}
            />
            <label>{steps[active]}</label>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 5
            }}>
                {steps[active] && steps.map((label: string, key: number) => (
                    <span key={label} style={{borderRadius: 8, background: active >= key ? '#FFD60E' : '#C7C7C7', padding: 4, flexGrow: 1}}></span>
                ))}
            </div>
        </div> : null}
    </React.Fragment>
}

export default SteppersCustom;
