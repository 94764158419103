import { Labelconfirm } from './Labelconfirm';
import { usePlansDataStore } from '@store/plansStore';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { usePolicyStore } from '@store/policyStore';

export const Quotas = () => {
  const { selectedPlan } = usePlansDataStore();
  const { Packages } = useGetQuotePayment();
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy } = usePolicyStore();
  const [totalPayment, setTotalPayment] = useState<string>('');
  let planName = '';
  let position = 0;

  // // @ts-ignore
  // if (selectedPlan.package === 'AMP') {
  //   planName = 'Plan Amplio';
  //   position = 0;
  // }
  // // @ts-ignore
  // if (selectedPlan.package === 'LIMIT') {
  //   planName = 'Plan Limitado';
  //   position = 1;
  // }
  // // @ts-ignore
  // if (selectedPlan.package === 'RC') {
  //   planName = 'Plan Básico';
  //   position = 2;
  // }
  if (selectedQuote.package === 'AMP') {
    planName = 'Amplio';
    position = 0;
  }
  if (selectedQuote.package === 'LIMIT') {
    planName = 'Limitado';
    position = 1;
  }
  if (selectedQuote.package === 'RC') {
    planName = 'Responsabilidad Civil';
    position = 2;
  }
  useEffect(() => {
    let totalNumber = 0;
    selectedQuote.available_packages[position].receipts.map((item: any) => {
      const number = Number(item?.total_premium);
      totalNumber += number;
    });
    setTotalPayment(totalNumber.toString());
  }, []);
  return (
    <>
      <Labelconfirm
        title={`Total`}
        value={`$${parseFloat(
          parseFloat(totalPayment).toFixed(2)
        ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}`}
      />
      <Labelconfirm
        title="Modalidad de pago"
        value={
          selectedQuote.payment_option === 'M'
            ? 'Mensual'
            : selectedQuote.payment_option === '3M'
            ? 'Trimestral'
            : selectedQuote.payment_option === '6M'
            ? 'Semestral'
            : 'Contado'
        }
      />

      {selectedQuote.payment_option !== 'A' && (
        <Box
          display="flex"
          justifyContent="space-between"
          margin="0px 0px 20px 0px"
          marginTop={'25px'}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'normal', color: '#424242' }}
            textAlign="start"
            fontSize={14}
          >
            Primer pago de
            <ul
              style={{
                margin: 0,
              }}
            >
              <li style={{ color: '#8F8F8F', fontWeight: 400 }}>
                {selectedQuote.payment_option === '6M'
                  ? 'Más un pago de'
                  : `Más ${
                      selectedQuote.available_packages[position].receipts.length - 1
                    } pagos de`}
              </li>
            </ul>
          </Typography>
          <Typography fontWeight="600" fontSize="16px">
            <span style={{ marginLeft: '19px' }}>
              +{' '}
              {`$${parseFloat(
                parseFloat(
                  selectedQuote.available_packages[position].receipts[0]
                    .total_premium
                ).toFixed(2)
              ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}`}
            </span>
            <ul
              style={{
                margin: 0,
              }}
            >
              <li
                style={{
                  color: '#8F8F8F',
                  fontWeight: 400,
                  fontSize: 14,
                }}
              >
                +{' '}
                {`$${parseFloat(
                  parseFloat(
                    selectedQuote?.available_packages[position]?.receipts[1]?.total_premium
                  ).toFixed(2)
                ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}`}
              </li>
            </ul>
          </Typography>
        </Box>
      )}
      <Labelconfirm title="Tipo de plan " value={planName} />
    </>
  );
};

// falta implementar el estado para terminar esta tarea 1018
