import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Box, Container, useTheme, useMediaQuery, Button } from '@mui/material';

import ImageBackground from '@assets/images/circless.png';
import { AccordionHiring } from '@screens/hiring/AccordionHiring';
import { Toats } from '@components/alerts/Toats';
import { useChangePrice } from '@hooks/useChangePrice';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useHistory } from 'react-router-dom';
import { useCPStore } from '@store/cpStore';
import { useTokenStore } from '@store/TokenStore';
import { CustomButton } from '@buttons/CustomButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {PolicyNumber} from "@screens/hiring/confirm/policyNumber/PolicyNumber";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
}));

export const Hiring: React.FC = () => {
  const { searchCP } = useCPStore();
  const { token } = useTokenStore();
  const { car } = useCarStore();
  const { driver } = useDriverStore();
  const [zip, setZip] = useState<string>(driver.codigoPostal || '');
  const { selectedQuote } = useSelectedQuoteStore();

  const history = useHistory();

  const [showToast, setShowToast] = useState(true);
  const [showErrorToast, setShowErrorToast] = useState(false);
    const [activeStep, setActiveStep] = useState<number>(0);

  const classes = useStyles();
  const { breakpoints, palette } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  useEffect(() => {
    window.scroll(0, 0);
    searchCP(zip, token);
  }, []);

  useChangePrice();

  if (
    car.assembler === '' ||
    driver.codigoPostal === '' ||
    selectedQuote.insurance === undefined
  ) {
    history.push('/insurer-details');
  }

  return (
    <div className={classes.root}>
      <Toats
        showToast={showToast}
        setShowToast={setShowToast}
        Text="Toma en cuenta que los datos que nos compartas en esta sección serán los que usaremos para la póliza de tu seguro. Valida que sean los correctos, porque si algo está mal implica un trámite adicional."
        isWarning
      />
      <Toats
        showToast={showErrorToast}
        setShowToast={setShowErrorToast}
        Text="¡Debes llenar todos los campos que son obligatorios! Checa cuál te falta para continuar."
      />
      {/*{!isTablet && (
        <Box
          component={'img'}
          src={ImageBackground}
          sx={{
            width: '100%',
          }}
          position={'absolute'}
          marginTop={20}
          alt={'Circle Background'}
          style={{ objectFit: 'cover', borderRadius: 8 }}
        />
      )}*/}
        {/* <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: 100,
            position: 'relative',
          }}
        >
          <CustomButton
            text={'Regresar'}
            onClick={() => history.push('/insurer-details')}
            size={isDesktop ? 'medium' : 'small'}
            variant={'text'}
            startIcon={<KeyboardArrowLeftIcon />}
            style={{
              color: palette.primary.main,
              fontSize: isMobile ? 14 : 16,
              textTransform: 'none',
              fontWeight: 'normal',
              textDecoration: 'underline',
            }}
          />
        </Box> */}
      <Container maxWidth={'lg'}>
          <Box
              display={'flex'}
              sx={{width:'100%',
                  flexGrow: 1,
                  alignItems:'center',
                  marginTop: {xs:16,sm:18,md:13},
                  display: isMobile ? 'block' : 'flex', justifyContent: 'space-between',
                  flexDirection:'row',
              }}
          >
              <Box sx={{flex:{xs:1,sm:.5,md:1}}}>
                  <CustomButton
                      text="Regresar"
                      onClick={() => {
                          activeStep === 0 ?
                          history.push('/insurer-details')
                              :
                              setActiveStep(activeStep - 1)
                      }
                      }
                      size={isDesktop ? 'medium' : 'small'}
                      variant={'text'}
                      startIcon={<ArrowBackIosIcon />}
                      style={{
                          marginBottom: isTablet  ? 5 : 0 ,
                          color: palette.primary.main,
                          fontSize: isMobile ? '0.64rem' : '14px',
                          textTransform: 'none',
                          fontWeight: 400,
                          textDecoration: 'underline',
                      }}
                  />
              </Box>
              <Box sx={{flex:1}}>
                  <PolicyNumber />
              </Box>
        </Box>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
              width:'100%',
            marginTop: 40,
            minHeight: '70vh',
            position: 'relative',
          }}
        >
          {car.assembler !== '' &&
            driver.codigoPostal !== '' &&
            selectedQuote.insurance !== undefined && (
              <AccordionHiring
                  activeStep ={activeStep}
                  setActiveStep={setActiveStep}
                  setShowToast={setShowToast}
                setShowErrorToast={setShowErrorToast}
              />
            )}
        </Box>
      </Container>
    </div>
  );
};
