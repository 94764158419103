// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import { Check, Error, Warning } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReactTooltip from 'react-tooltip';


import {
  Box,
  useTheme,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { es } from 'date-fns/locale';

import { CustomButton } from '@buttons/CustomButton';
import { calculateAge } from '@utils/utilityFunctions';
import { useDriverStore } from '@store/driverInfoStore';
import { useCPStore } from '@store/cpStore';
import { getTracking } from '@api/getTracking';
import { CPCustomMask } from '../inputCustomMasks/CPCustomMask';
import { getCPById } from '@api/getCP';
import { createContact } from '@api/createContact';
import { useTokenStore } from '@store/TokenStore';
import { useCarListStore } from '@store/carListStore';
import { useCarStore } from '@store/carInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useTrackingStore } from '@store/TrackingStore';
import { usePolicyStore } from '@store/policyStore';
import { upCRM } from '@utils/CRM';
import { formatDate } from '@utils/formatDate';
import './tooltipCP.css';

interface FormInformationProps {
  setMessageComponent: (value: boolean) => void;
  setDriverMessage: (value: boolean) => void;
  setGenderMessage: (value: boolean) => void;
  genderMessage: boolean;
  driverMessage: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const FormInformation: React.FC<FormInformationProps> = ({
  setMessageComponent,
  setDriverMessage,
  driverMessage,
  genderMessage,
  setGenderMessage,
  isMobile,
  isTablet,
  isDesktop,
}) => {
  const { palette } = useTheme();
  const { driver, setDriver } = useDriverStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { tracking, setTracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { searchCP } = useCPStore();
  const { token } = useTokenStore();
  const { car } = useCarStore();
  const [colorM, setColorM] = useState<string>('transparent');
  const [colorF, setColorF] = useState<string>('rgb(35, 141, 193, 20%)');
  const [anterior, setAnterior] = useState<string>(driver.genero || 'Mujer');
  const [backM, setBackM] = useState<string>('#424242');
  const [backF, setBackF] = useState<string>(palette.primary.main);
  const [gender, setGender] = useState<string>(driver.genero || 'Mujer');
  const [birthDate, setBirthDate] = useState<Date | null>(
    driver.fechaNacimiento || new Date()
  );
  const { policy, addPolicyInfo } = usePolicyStore();
  const [showAlerts, setShowAlerts] = useState(false);
  const [zip, setZip] = useState<string>(driver.codigoPostal || '');
  const [validateFields, setValidateFields] = useState({
    zip: false,
    birthday: false
  })
  const [errorAge, setErrorAge] = useState<{ msg: string; isError: boolean }>({
    msg: '',
    isError: false,
  });
  const [errorZip, setErrorZip] = useState<{ msg: string; isError: boolean }>({
    msg: '',
    isError: false,
  });
  const [warningZip, setWarningZip] = useState<{
    msg: string;
    isWarning: boolean;
  }>({
    msg: '',
    isWarning: false,
  });

  const [Limit, setLimit] = useState<any>({
    MaxAgeAcept: new Date(),
    MinAgeAcept: new Date(),
  });
  const { carList } =
    useCarListStore();


  const history = useHistory();

  const CalcYear = () => {
    const MaxAgeAcept = 99;
    const MinAgeAcept = 18;
    const currentYear = new Date().getFullYear();
    setLimit({
      MaxAgeAcept: new Date(`${currentYear - MinAgeAcept}-08-18`),
      MinAgeAcept: new Date(`${currentYear - MaxAgeAcept}-08-18`),
    });
  };

  useEffect(() => {
    if (gender === 'Hombre') {
      setColorM('rgb(35, 141, 193, 20%)');
      setColorF('transparent');
      setBackM(palette.primary.main);
      setBackF('#424242');
    } else {
      setColorF('rgb(35, 141, 193, 20%)');
      setColorM('transparent');
      setBackM('#424242');
      setBackF(palette.primary.main);
    }
    CalcYear();
  }, []);

  useEffect(() => {
    (async() => {
      if(policy.policyNumber !== '001') {
        await setTracking(token)
      }
    })()
  },[policy.policyNumber, setTracking, token])

  const handleChangeGender = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment != null && anterior != null) {
      setAnterior(newAlignment);
    } else if (anterior != null) {
      newAlignment = anterior;
    } else {
      newAlignment = 'Mujer';
    }
    setGender(newAlignment);
    if (newAlignment === 'Hombre' || newAlignment == null) {
      setColorM('rgb(35, 141, 193, 20%)');
      setColorF('transparent');
      setBackM(palette.primary.main);
      setBackF('#424242');
    } else {
      setColorF('rgb(35, 141, 193, 20%)');
      setColorM('transparent');
      setBackM('#424242');
      setBackF(palette.primary.main);
    }
    setDriver({
      ...driver,
      genero: newAlignment,
    });
  };

  const handleChangeDriverBirthday = (newValue: Date | null) => {
    if (newValue == null) return 
    setValidateFields({
      ...validateFields,
      birthday: true
    })
    // @ts-ignore
    setBirthDate(formatDate(newValue));
    setDriver({
      ...driver,
      fechaNacimiento: newValue,
    });
    const isDate = newValue instanceof Date && !isNaN(newValue.valueOf())
    if(!isDate){
      setErrorAge({
        msg: 'La fecha de nacimiento no es válida.',
        isError: true,
      });
      return;
    }
    setShowAlerts(false);
    setErrorAge({
      msg: '',
      isError: false,
    });
    
  };

  const handleCreateDriver = async () => {
    if (calculateAge(birthDate) >= 18 && calculateAge(birthDate) <= 99) {
      setDriver({
        ...driver,
        genero: gender,
        fechaNacimiento: birthDate,
        codigoPostal: zip,
      });
      setErrorAge({
        msg: '',
        isError: false,
      });

      setErrorZip({ msg: '', isError: false });
      setWarningZip({ msg: '', isWarning: false });

      if (zip.length < 1) {
        setErrorZip({
          msg: '¡Oops! Parece que hay un error en tu Código Postal.',
          isError: true,
        });
      } else {
        const data = await getCPById(zip, token);
        cpError(data);
      }
      setShowAlerts(false);
    } else {
      setErrorAge({
        msg: 'Por favor ingresar una edad valida mayor a 17 años y menor o igual a 99 años',
        isError: true,
      });
      setShowAlerts(true);
    }
  };

  const cpError = async (data: any) => {
    if (data.errors) {
      setErrorZip({
        msg: '¡Oops! Parece que hay un error en tu Código Postal.',
        isError: true,
      });
      return;
    }
    await searchCP(zip, token);
    if (tracking.id === undefined ||  tracking?.id === undefined) {
      setTracking(token);
    }
    let id = tracking.id;
    let crm = {
      business: 'coppel-hdi',
      tracking_id: id,
      name: driver.name,
      cellphone: driver.telefono,
      last_name: '',
      second_last_name: '',
      phone: driver.telefono,
      sub_channel_sale: 'COPPEL EMPLEADOS',
      email: driver.email,
      gender: driver.genero,
      birth_date: driver.fechaNacimiento,
      township_code: '',
      suburb_code: '',
      vehicle_id: car.vehicle_id,
      rfc: '',
      address: '',
      suburb_name: '',
      township_name: '',
      Tiporelacion: '',
      zip_code: driver.codigoPostal,
      insurance: '',
      campana: 'COPPEL EMPLEADOS',
      portal_origen: 'https://flotillascoppel.inter.mx',
      grupo_economico: 'COPPEL EMPLEADOS',
    };
    let crmresponse = await createContact(token, crm);
    policy.policyNumber = crmresponse.data.Folio;
    policy.stage = '1. Registro (Coche)';
    policy.sub_stage = '1. Datos Generales';
    policy.state_code = 'Borrador';
    policy.status_code = 'En revisión';
    await addPolicyInfo({ ...policy });
    let initialData = {
      id: tracking.id,
      business: 'coppel-hdi',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
        carList: carList,
      },
    };
    await getTracking(token, initialData);
    upCRM(token, car, driver, selectedQuote, policy, tracking, cpData);
    history.push('/insurer-details');
  };

  const colorHelperTextCP = () => {
    if (warningZip.isWarning) {
      return '#FF9E1B';
    }

    if (errorZip.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const cpRighIcon = () => {
    if (warningZip.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorZip.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'primary'} />;
  };

  const colorCPAdornment = () => {
    if (warningZip.isWarning) {
      return 'warning';
    }

    if (errorZip.isError) {
      return 'error';
    }
  };

  return (
    <Box
      width={isMobile ? '325px' : '364px'}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="10px"
      marginBottom="80px"
    >
      
      <Box
        padding={`${isMobile ? '8px 15px' : '20px 15px'}`}
        marginTop={isTablet ? 2 : 0}
        width="91%"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          margin: `${isMobile ? '0px 0px 0px 0px' : '0px 0px 0px 0px'}`,
        }}
      >
        <Typography marginBottom={isMobile ? '15px' : '30px'} textAlign="start">
          Ingresa la información del conductor del vehículo asegurado
        </Typography>
        <Typography fontSize={'14px'} textAlign="start" marginBottom="10px">
          Sexo de nacimiento
        </Typography>
        <ToggleButtonGroup
          fullWidth
          sx={{
            height: 56,
            backgroundColor: '#F4F4F4',
          }}
          exclusive
          size={'medium'}
          value={gender}
          onChange={handleChangeGender}
          style={{ borderRadius: 8, marginBottom: 40, padding: 3 }}
        >
          <ToggleButton
            sx={{
              textTransform: 'none',
              backgroundColor: '#fafafa',
            }}
            value="Mujer"
            style={{
              fontWeight: 600,
              borderRadius: 8,
              border: 'none',
              textTransform: 'none',
              color: backF,
              backgroundColor: colorF,
            }}
          >
            Mujer
          </ToggleButton>
          <ToggleButton
            value="Hombre"
            style={{
              fontWeight: 600,
              borderRadius: 8,
              border: 'none',
              textTransform: 'none',
              color: backM,
              backgroundColor: colorM,
            }}
          >
            Hombre
          </ToggleButton>
        </ToggleButtonGroup>
        {/* PARA MOBILE FALTA CAMBIAR MOBILEDATEPICKER */}
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          <DatePicker
            maxDate={Limit.MaxAgeAcept}
            minDate={Limit.MinAgeAcept}
            openTo="year"
            views={['year', 'month', 'day']}
            label="Fecha de nacimiento"
            inputFormat="dd/MM/yyyy"
            value={new Date(`${birthDate}T00:00:00`)}
            onChange={handleChangeDriverBirthday}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={errorAge.isError}
                helperText={
                  <label style={{ color: '#f44336' }}>
                    {errorAge.msg}
                  </label>
                }
              />
            )}
          />
          {
            showAlerts && 
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="outlined" severity="error" >
                Para contratar este producto tienes que tener una edad máxima de X.
              </Alert>
              <Alert variant="outlined" severity="error">
                Para contratar este producto tienes que tener una edad mínima de 18 años.
              </Alert>
            </Stack>
          }
        </LocalizationProvider>
        <Box marginTop={2}>
          <ReactTooltip
            id="cp"
            type="light"
            place={isTablet || isMobile ? "top" : "right"}
            effect="solid"
            backgroundColor="#EBF8FB"
            class={"__react_component_tooltipcp"}
          >
            <Typography maxWidth={350}>
              Ingresa el código postal donde duerme tu coche
            </Typography>
          </ReactTooltip>
          <TextField
            data-for="cp"
            data-tip={''}
            data-iscapture={'true'}
            value={zip}
            onChange={(e) => {
              setZip(e.target.value);
              setValidateFields({
                ...validateFields,
                zip: true
              })
              setDriver({
                ...driver,
                codigoPostal: e.target.value,
              });

              if (e.target.value.length === 4) {
                setWarningZip({
                  msg: '¿El Código Postal tiene menos de 5 números? Agrega un 0 a la izquierda. Ejemplo: 01000',
                  isWarning: true,
                });
                setErrorZip({ msg: '', isError: false });
                return;
              }
              setWarningZip({ msg: '', isWarning: false });
              if (calculateAge(birthDate) >= 18)
                setErrorZip({ msg: '', isError: false });
            }}
            id="outlined-basic"
            fullWidth
            label="Código postal*"
            variant="outlined"
            maxRows={4}
            style={{ marginBottom: '20px' }}
            color={colorCPAdornment()}
            error={errorZip.isError}
            helperText={
              <label style={{ color: colorHelperTextCP() }}>
                {errorZip.msg || warningZip.msg}
              </label>
            }
            InputProps={{
              inputComponent: CPCustomMask as any,
              style: {
                borderRadius: 8,
              },
              endAdornment: validateFields.zip && cpRighIcon(),
            }}
          />
        </Box>
      </Box>
      <Box width="100%">
        {!validator.isPostalCode(zip, 'MX') ? (
          <CustomButton
            color={'primary'}
            onClick={handleCreateDriver}
            variant={'contained'}
            text={'COTIZAR'}
            style={{
              width: '100%',
              height: '56px',
              textTransform: 'uppercase',
            }}
          />
        ) : (
          <CustomButton
            color={'primary'}
            onClick={handleCreateDriver}
            variant={'contained'}
            text={'Continuar'}
            style={{
              width: '100%',
              height: '56px',
            }}
          />
        )}
      </Box>
    </Box>
  );
};
